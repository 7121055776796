import { FC } from 'react';
import { observer } from 'mobx-react';
import { Icon, useModal } from '@farmlink/farmik-ui';

import { EExperimentStatus } from '../../../../../../../../../../../../../../api/models/as-fields/experiments';
import { useStore } from '../../../../../../../../../../../../../common/utils/helpers/mobx';
import { CreateExperimentStore } from '../../../../../../../../mobx/stores';
import { zonesModalConfig } from '../../../../config/modals';
import { ZonesStore } from '../../../../mobx/stores';
import { ICultureZoneCell } from '../../utils/hooks/zonesListTableHooks/zonesListTable.hooks';

import Styled from './ZoneListActionsCell.styles';

interface IZoneListNameCellProps {
  rowData: ICultureZoneCell;
}

export const ZoneListActionsCell: FC<IZoneListNameCellProps> = observer(({ rowData }) => {
  const { setEditableZoneId } = useStore(ZonesStore);
  const { selectedExp } = useStore(CreateExperimentStore);

  const { openModalByModalId } = useModal();

  const isDisabled =
    !rowData?.fwExpCultureZone?.isSelected || selectedExp?.status !== EExperimentStatus.Draft;

  const openEditZoneModal = () => {
    if (isDisabled) {
      return;
    }

    setEditableZoneId(rowData?.fwExpCultureZone?.cultureZoneId);
    openModalByModalId(zonesModalConfig.name.editZoneInfo, { isSaveChanges: true });
  };

  return (
    <Styled.Wrapper $disabled={isDisabled} data-test-id={'zones-table-actions-cell-wrapper'}>
      <Icon
        icon="edit"
        size="medium"
        onClick={openEditZoneModal}
        data-test-id={'zones-table-actions-cell'}
      />
    </Styled.Wrapper>
  );
});
