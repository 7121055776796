import { forwardRef, memo } from 'react';

import { useTableBuilderUIContext as useContext } from '../../../context/UI/TableBuilderUIContext/hooks';
import { useTableBuilderDataTestId as useDataTestId } from '../../../hooks';
import { ITableBuilderRowsGroup as IRowsGroup } from '../../../models/data/TableBuilderRowsGroup';
import { TableBuilderRowsContainer as RowsContainer } from '../../rows/TableBuilderRowsContainer';

import Styled from './TableBuilderRowsGroupAutoRenderContainer.styles';

interface IProps {
  rowsGroup: IRowsGroup;
  borderType: string;
  scrollPadding?: string;
  isHideScroll?: boolean;
}

const TableBuilderRowsGroupAutoRenderContainer = forwardRef<HTMLDivElement, IProps>(
  ({ rowsGroup, borderType, scrollPadding, isHideScroll }, ref) => {
    const context = useContext();

    const getDataTestId = useDataTestId(context.builderId);

    return (
      <Styled.Wrapper {...getDataTestId('auto-rows-group')}>
        <Styled.Title {...getDataTestId('auto-rows-title')}>
          {rowsGroup.autoRenderConfig.name}
        </Styled.Title>
        <RowsContainer
          ref={ref}
          rowsGroupId={rowsGroup.id}
          rowIdList={rowsGroup.rowIdList}
          borderType={borderType}
          scrollPadding={scrollPadding}
          isHideScroll={isHideScroll}
        />
      </Styled.Wrapper>
    );
  }
);

TableBuilderRowsGroupAutoRenderContainer.displayName = 'TableBuilderRowsGroupAutoRenderContainer';

export default memo(TableBuilderRowsGroupAutoRenderContainer);
