import { observer } from 'mobx-react';
import { FC, memo, useState, useEffect, useMemo } from 'react';
import { AutoTooltip, Colors } from '@farmlink/farmik-ui';
import { generatePath, useNavigate } from 'react-router-dom';
import { IconNames } from '@farmlink/farmik-ui/dist/Icon';

import { SidePanel, TSidePanelProps } from '../../../../../common/features/UI/SidePanel';
import { useStore } from '../../../../../common/utils/helpers/mobx';
import { formatDate } from '../../../../../common/utils/helpers/dates';
import { useShowTooltip, useWarningBeforeDeleting } from '../../../../../common/utils/hooks';
import { AccessStore } from '../../../../../common/mobx/stores/AccessStore';
import { ContractsListController } from '../ContractsList/mobx/controllers';
import { useDataTestIdV2 } from '../../../../../common/utils/hooks/locators';
import { ActionsMenu } from '../../../../../common/features/UI/ActionsMenu';
import { IMenuItem } from '../../../../../common/features/UI/ActionsMenu/ActionsMenu';
import { ECreateEditContractRoute } from '../../modules/CreateEditContract/routes';

import { ContractSidePanelController } from './mobx/controllers';
import { ContractSidePanelStore } from './mobx/stores';
import { ContractSidePanelService } from './mobx/services';
import { DocumentsTab, ExperimentsTab } from './components';
import { Tabs, ETabs } from './components/Tabs';
import Styled from './ContractSidePanel.styles';

type TProps = Omit<TSidePanelProps, 'children'>;

const ContractSidePanel: FC<TProps> = ({ onClose }) => {
  const contractsListController = useStore(ContractsListController);
  const { fetchContractList } = contractsListController;

  const getDataTestId = useDataTestIdV2('contract-side-panel');

  const { isAllowToEditContracts } = useStore(AccessStore);

  const { getAccessToEditContract } = useStore(ContractSidePanelController);

  const { selectedContract, hasAccessToEditContracts, clearStore } =
    useStore(ContractSidePanelStore);

  const { deleteContract } = useStore(ContractSidePanelService);

  const { ref, showTooltip } = useShowTooltip<HTMLDivElement>();

  const [activeTab, setActiveTab] = useState<ETabs>(ETabs.Experiments);
  const { name, contractDate, organization, canDelete } = selectedContract || {};

  const { showWarningBeforeDeleting } = useWarningBeforeDeleting();

  const navigate = useNavigate();

  const menuConfig = useMemo<IMenuItem[]>(() => {
    const menuItems = [
      {
        icon: 'edit' as IconNames,
        onClick: () => {
          navigate(
            generatePath(ECreateEditContractRoute.Edit, {
              contractId: selectedContract?.id,
            })
          );
        },
        colorScheme: {
          default: {
            background: Colors.primaryWhite,
            icon: Colors.black,
          },
          hover: {
            background: Colors.primaryWhite,
            icon: Colors.hoverGreen,
          },
        },
      },
      {
        icon: 'close' as IconNames,
        onClick: onClose,
        colorScheme: {
          default: {
            background: Colors.secondaryGray,
            icon: Colors.black,
          },
          hover: {
            background: Colors.lightGreen,
            icon: Colors.hoverGreen,
          },
        },
      },
    ];

    if (canDelete) {
      menuItems.unshift({
        icon: 'bin' as IconNames,
        onClick: () => {
          try {
            showWarningBeforeDeleting('договор', deleteContractAction);
          } catch (e) {
            console.log(e);
          }
        },
        colorScheme: {
          default: {
            background: Colors.lightPink,
            icon: Colors.red,
          },
          hover: {
            background: Colors.lightPink,
            icon: Colors.hoverPink,
          },
        },
      });
    }

    return menuItems;
  }, [selectedContract.id, canDelete]);

  useEffect(
    () => () => {
      clearStore();
    },
    []
  );

  useEffect(() => {
    if (organization?.id) {
      getAccessToEditContract(organization.id);
    }
  }, [organization?.id]);

  const deleteContractAction = async () => {
    await deleteContract(selectedContract?.id);
    await fetchContractList(false);
    onClose();
  };

  return (
    <SidePanel onClose={onClose}>
      <Styled.Wrapper {...getDataTestId()}>
        <Styled.Header {...getDataTestId('header-wrapper')}>
          <AutoTooltip position="bottom" content={`Договор № ${name}`} disabled={!showTooltip}>
            <Styled.ContractNumber ref={ref}>Договор № {name}</Styled.ContractNumber>
          </AutoTooltip>
          <Styled.ActionsWrapper {...getDataTestId('actions-wrapper')}>
            {(isAllowToEditContracts || hasAccessToEditContracts) && (
              <ActionsMenu
                dataTestId={getDataTestId()['data-test-id']}
                menuConfig={menuConfig}
                dataId={selectedContract?.id}
              />
            )}
          </Styled.ActionsWrapper>
        </Styled.Header>
        <Styled.SubHeaderWrapper>
          {organization.name}
          <Styled.Delimiter />
          {formatDate(new Date(contractDate))}
        </Styled.SubHeaderWrapper>
        <Tabs activeTab={activeTab} onTabClick={setActiveTab} />
        {activeTab === ETabs.Experiments && <ExperimentsTab />}
        {activeTab === ETabs.Documents && (
          <DocumentsTab isAllowToEditContracts={isAllowToEditContracts} />
        )}
      </Styled.Wrapper>
    </SidePanel>
  );
};

ContractSidePanel.displayName = 'ContractSidePanel';

export default memo(observer(ContractSidePanel));
