import { FC, useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { useForm } from '../../../../../../../../../../../common/features/form/utils/hooks';
import {
  IInventoryValuesForm,
  createInventoryValuesFormConfig,
  INVENTORY_VALUES_FORM_KEY,
} from '../../configs/forms/inventoryValuesForm';
import { useStore } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { HistoryStore } from '../../mobx/stores/History.store';
import { InventoryValueController } from '../../mobx/controllers/InventoryValue.controller';
import { CreateExperimentStore } from '../../../../../../mobx/stores';
import { InventoryValueStore } from '../../../../../calculation/containers/Calculation/mobx/store/Calculation/InventoryValue.store';
import { useIsOverflow } from '../../../../../../../../../../../common/utils/hooks';

import Styled from './InventoryValuesForm.style';

interface IInventoryValuesFormProps {
  formType: 'add' | 'edit';
  inventoryValueType?: 'protection' | 'fertilizer';
}

const InventoryValuesForm: FC<IInventoryValuesFormProps> = ({ formType, inventoryValueType }) => {
  const { selectedExp } = useStore(CreateExperimentStore);

  const [checked, setChecked] = useState(false);
  const [previousTypeValue, setPreviousTypeValue] = useState(null);
  const [isEdited, setIsEdited] = useState(false);

  const { experimentId } = useParams<{ experimentId: string }>();
  const form = useForm<IInventoryValuesForm>(INVENTORY_VALUES_FORM_KEY);
  const {
    elements,
    registerForm,
    submitForm,
    addOptionList,
    changeListOfFormValue,
    formData,
    initialFormData,
    changeInitialFormValue,
    blockElement,
    addPaginationConfig,
    changeElementLabel,
  } = form;

  const isFormChanged = useMemo(
    () => !_.isEqual(formData, initialFormData),
    [formData, initialFormData]
  );

  const isRequiredFieldsNotEmpty = useMemo(
    () => _.values(_.omit(formData, ['typeValue'])).every(item => Boolean(item)),
    [formData]
  );

  const { typeValue: Type, nameValue: Name, dosage: Dosage } = elements;

  const {
    currentFertilizerInventoryValue,
    activeCultureZone,
    currentTechOperation,
    nutritionHistories,
    currentProtectionInventoryValue,
  } = useStore(HistoryStore);
  const {
    fertilizerCurrentPage,
    fertilizerTotalPages,
    fertilizeSearchQuery,
    setFertilizeOptionList,
    setFertilizerCurrentPage,
    fertilizeOptionList,
    protectionCurrentPage,
    protectionTotalPages,
    protectionSearchQuery,
    setProtectionOptionList,
    setProtectionCurrentPage,
    protectionOptionList,
    measureInfo,
    previousNameValue,
    setPreviousNameValue,
    setFormType,
  } = useStore(InventoryValueStore);

  const {
    createInventoryValue,
    updateFertilizerInventoryValue,
    updateProtectionInventoryValue,
    fertilizersSearchQueryHandler,
    changeFertilizerPageNumber,
    onFertilizeListScroll,
    protectionsSearchQueryHandler,
    getInventoryValueOptionListFromItemsList,
    getInventoryValueOptionListFromProtectionsList,
    onProtectionListScroll,
    changeProtectionPageNumber,
    fetchProtectionMeasure,
  } = useStore(InventoryValueController);

  const { closeModal, openModalByModalId } = useModal();

  const ref = useRef();
  const isOverflow = useIsOverflow(ref);

  const currentExperimentId = useMemo(
    () => selectedExp?.id ?? experimentId,
    [selectedExp, experimentId]
  );

  const onCreate = useCallback(() => {
    submitForm(async forms => {
      const defaultMeasureKG = 'f357cb40-6d3a-11eb-8d42-dd19d7aaf478';

      await createInventoryValue(
        forms,
        currentTechOperation,
        activeCultureZone,
        forms?.typeValue === 'fertilizer' ? defaultMeasureKG : measureInfo?.id,
        currentExperimentId
      );

      if (checked) {
        openModalByModalId(`addNutritionHistoryInventoryValues`);
        changeListOfFormValue({ typeValue: '', nameValue: '', dosage: '' });
        changeInitialFormValue({ typeValue: '', nameValue: '', dosage: '' });

        setChecked(false);
      } else {
        closeModal();
      }
    });
  }, [activeCultureZone, currentExperimentId, measureInfo, currentTechOperation, checked]);

  const isNeedToDeleteOldAndCreateNew = useMemo(
    () => formType === 'edit' && formData?.nameValue !== previousNameValue,
    [formData?.nameValue, formType, previousNameValue]
  );

  const onEdit = useCallback(() => {
    submitForm(async forms => {
      if (formData?.typeValue === 'fertilizer') {
        await updateFertilizerInventoryValue(
          currentFertilizerInventoryValue?.id || formData?.nameValue,
          forms,
          activeCultureZone,
          currentExperimentId,
          isNeedToDeleteOldAndCreateNew,
          previousTypeValue,
          previousNameValue
        );
      } else if (formData?.typeValue === 'protection') {
        await updateProtectionInventoryValue(
          currentProtectionInventoryValue?.id || formData?.nameValue,
          forms,
          activeCultureZone,
          currentExperimentId,
          isNeedToDeleteOldAndCreateNew,
          previousTypeValue,
          previousNameValue
        );
      }

      closeModal();
    });
  }, [
    activeCultureZone,
    currentExperimentId,
    formData?.typeValue,
    currentFertilizerInventoryValue,
    currentProtectionInventoryValue,
    isNeedToDeleteOldAndCreateNew,
    previousTypeValue,
    previousNameValue,
  ]);

  useEffect(() => {
    switch (inventoryValueType) {
      case 'protection':
        registerForm(
          createInventoryValuesFormConfig({
            protectionsSearchQueryHandler,
          })
        );

        break;
      case 'fertilizer':
        registerForm(
          createInventoryValuesFormConfig({
            fertilizersSearchQueryHandler,
          })
        );

        break;
      default:
        registerForm(
          createInventoryValuesFormConfig({
            fertilizersSearchQueryHandler,
          })
        );

        break;
    }
  }, [inventoryValueType]);

  useEffect(() => {
    /**
     * To Do: заменить регистрирование новой формы на возможность менять querySearchHandler у селекта
     */
    if (formType === 'add' || (formType === 'edit' && isEdited)) {
      switch (formData?.typeValue) {
        case 'protection':
          registerForm(
            createInventoryValuesFormConfig({
              protectionsSearchQueryHandler,
            })
          );

          changeListOfFormValue({ typeValue: 'protection' });
          blockElement('nameValue', false);

          break;
        case 'fertilizer':
          registerForm(
            createInventoryValuesFormConfig({
              fertilizersSearchQueryHandler,
            })
          );

          changeListOfFormValue({ typeValue: 'fertilizer' });
          blockElement('nameValue', false);

          break;
        default:
          changeListOfFormValue({ nameValue: '' });
          blockElement('nameValue', true);

          break;
      }
    }

    if (formData?.typeValue && formData?.typeValue === previousTypeValue) {
      setIsEdited(true);
    }
  }, [formData?.typeValue]);

  useEffect(() => {
    (async () => {
      if (
        (formType === 'add' && formData?.typeValue === 'fertilizer') ||
        (formType === 'edit' && isEdited && formData?.typeValue === 'fertilizer')
      ) {
        /**
         * Получаем наименование тмц с пустым параметром query и 0 страницей
         */
        const { fertilizerList } = await getInventoryValueOptionListFromItemsList('', 0);

        const [fertilizer] = fertilizerList;

        /**
         * Запоминаем лист опций на будущее, если его придется копировать
         */
        setFertilizeOptionList(fertilizer);

        /**
         * Меняем начальную страницу данных на 0
         */
        setFertilizerCurrentPage(0);

        addOptionList('nameValue', fertilizer);
      } else if (
        (formType === 'add' && formData?.typeValue === 'protection') ||
        (formType === 'edit' && isEdited && formData?.typeValue === 'protection')
      ) {
        /**
         * Получаем наименование тмц с пустым параметром query и 0 страницей
         */
        const { protectionList } = await getInventoryValueOptionListFromProtectionsList('', 0);

        const [protection] = protectionList;

        /**
         * Запоминаем лист опций на будущее, если его придется копировать
         */
        setProtectionOptionList(protection);

        /**
         * Меняем начальную страницу данных на 0
         */
        setProtectionCurrentPage(0);

        addOptionList('nameValue', protection);
      }
    })();
  }, [nutritionHistories]);

  const onFertilizeScroll = async () => {
    const fertilizers = await onFertilizeListScroll(fertilizeSearchQuery);

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('nameValue', [...fertilizeOptionList, ...fertilizers]);

    setFertilizeOptionList([...fertilizeOptionList, ...fertilizers]);
  };

  const onProtectionScroll = async () => {
    const protections = await onProtectionListScroll(protectionSearchQuery);

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('nameValue', [...protectionOptionList, ...protections]);

    setProtectionOptionList([...protectionOptionList, ...protections]);
  };

  useEffect(() => {
    if (formData?.typeValue === 'fertilizer') {
      addPaginationConfig('nameValue', {
        currentPage: fertilizerCurrentPage,
        totalPages: fertilizerTotalPages,
        onScroll: onFertilizeScroll,
        onPageChange: changeFertilizerPageNumber,
      });
    }
  }, [fertilizerCurrentPage, fertilizerTotalPages, onFertilizeScroll, changeFertilizerPageNumber]);

  useEffect(() => {
    if (formData?.typeValue === 'protection') {
      addPaginationConfig('nameValue', {
        currentPage: protectionCurrentPage,
        totalPages: protectionTotalPages,
        onScroll: onProtectionScroll,
        onPageChange: changeProtectionPageNumber,
      });
    }
  }, [protectionCurrentPage, protectionTotalPages, onProtectionScroll, changeProtectionPageNumber]);

  useEffect(() => {
    if (formData?.nameValue) {
      blockElement('dosage', false);
      blockElement('nameValue', false);

      if (formData?.typeValue === 'fertilizer') {
        changeElementLabel('dosage', 'Дозировка, кг/га');
      }

      if (formData?.typeValue === 'protection') {
        (async () => {
          const measureContent = await fetchProtectionMeasure(formData?.nameValue);

          changeElementLabel('dosage', `Дозировка, ${measureContent?.name}/га`);
        })();
      }
    } else {
      blockElement('dosage', true);
      changeListOfFormValue({ dosage: '' });

      changeElementLabel('dosage', 'Дозировка');
    }
  }, [formData?.nameValue]);

  useEffect(() => {
    setFormType(formType);

    if (formType === 'edit') {
      blockElement('dosage', false);

      /**
       * Когда режим редактирования, то опшен для поля "Наименование ТМЦ" явно
       * хардкодим из полученной модели. Это необходимо для того, чтобы правильно
       * отобразить наименование без лишних запросов на бэк.
       */
      if (currentFertilizerInventoryValue?.fertilizer && inventoryValueType === 'fertilizer') {
        addOptionList('nameValue', [
          {
            label: currentFertilizerInventoryValue?.fertilizer?.name,
            value: currentFertilizerInventoryValue?.fertilizer?.id,
          },
        ]);

        setPreviousNameValue(currentFertilizerInventoryValue?.fertilizer?.id);
        setPreviousTypeValue('fertilizer');

        changeListOfFormValue({ typeValue: 'fertilizer' });
      } else if (
        currentProtectionInventoryValue?.protection &&
        inventoryValueType === 'protection'
      ) {
        addOptionList('nameValue', [
          {
            label: currentProtectionInventoryValue?.protection?.name,
            value: currentProtectionInventoryValue?.protection?.id,
          },
        ]);

        setPreviousNameValue(currentProtectionInventoryValue?.protection?.id);
        setPreviousTypeValue('protection');

        changeListOfFormValue({ typeValue: 'protection' });
      }

      if (currentFertilizerInventoryValue && inventoryValueType === 'fertilizer') {
        changeListOfFormValue({
          dosage: currentFertilizerInventoryValue.concentration
            ? String(currentFertilizerInventoryValue.concentration)
            : '',
          nameValue: currentFertilizerInventoryValue.fertilizer.id,
        });
        changeInitialFormValue({
          dosage: currentFertilizerInventoryValue.concentration
            ? String(currentFertilizerInventoryValue.concentration)
            : '',
          nameValue: currentFertilizerInventoryValue.fertilizer.id,
        });
      } else if (currentProtectionInventoryValue && inventoryValueType === 'protection') {
        changeListOfFormValue({
          dosage: currentProtectionInventoryValue.concentration
            ? String(currentProtectionInventoryValue.concentration)
            : '',
          nameValue: currentProtectionInventoryValue.protection.id,
        });
        changeInitialFormValue({
          dosage: currentProtectionInventoryValue.concentration
            ? String(currentProtectionInventoryValue.concentration)
            : '',
          nameValue: currentProtectionInventoryValue.protection.id,
        });
      }
    }
  }, [
    formType,
    inventoryValueType,
    currentProtectionInventoryValue,
    currentFertilizerInventoryValue,
  ]);

  const successButton = useMemo(() => {
    return {
      title: 'Сохранить',
      handler: formType === 'add' ? onCreate : onEdit,
      disabled: formType === 'add' ? !isRequiredFieldsNotEmpty : !isFormChanged,
    };
  }, [formType, isFormChanged, isRequiredFieldsNotEmpty, onCreate, onEdit]);

  const denyButton = useMemo(() => {
    return { title: 'Отменить', handler: closeModal };
  }, [closeModal]);

  return (
    <Styled.Wrapper>
      <Styled.ContentWrapper ref={ref}>
        <Styled.Attribute width="100%" marginBottom="24px">
          {Type && <Type />}
        </Styled.Attribute>
        <Styled.Attribute width="100%" marginBottom="24px">
          {Name && <Name />}
        </Styled.Attribute>
        <Styled.Attribute width="100%" marginBottom="24px">
          {Dosage && <Dosage />}
        </Styled.Attribute>

        {formType === 'add' && (
          <Styled.CheckboxAttribute>
            <Styled.Checkbox value={checked} label="Добавить еще одно ТМЦ" onChange={setChecked} />
          </Styled.CheckboxAttribute>
        )}
      </Styled.ContentWrapper>

      <Styled.FooterWrapper $isOverflow={isOverflow}>
        <ModalFooter successButton={successButton} denyButton={denyButton} />
      </Styled.FooterWrapper>
    </Styled.Wrapper>
  );
};

export default observer(InventoryValuesForm);
