import { flatten } from 'lodash';
import { makeAutoObservable } from 'mobx';

import {
  IExperimentCultureZone,
  IExperimentStep,
} from '../../../../../../../../../../../../api/models/as-fields/experiments';
import { NutritionHistoryService } from '../../../../../../../../../../../common/mobx/services/as-fields';
import NutritionHistoryItemService from '../../../../../../../../../../../common/mobx/services/as-fields/NutritionHistoryItem/NutritionHistoryItem.service';
import { DictionaryService } from '../../../../../../../../../../../common/mobx/services/da-dictionary';
import { lazyInject, provide } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { toNumber } from '../../../../../../../../../../../common/utils/helpers/numbers';
import { createDictionaryEntitySelectOptionList } from '../../../../../../../../../../../common/utils/helpers/selectOptions';
import { IInventoryValuesForm } from '../../config/forms/inventoryValuesForm';
import { CalculationStore } from '../store/Calculation/Calculation.store';
import { InventoryValueStore } from '../store/Calculation/InventoryValue.store';
import { ISelectOption } from '../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { EExperimentStepType } from '../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentStep/ExperimentStep.model';
import { EExperimentCultureZoneType } from '../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { ICreateNutritionHistoryItemDto } from '../../../../../../../../../../../../api/models/as-fields/plan/NutrationHistory';

import { CalculationController } from './Calculation/Calculation.controller';

@provide.singleton()
export class InventoryValueController {
  @lazyInject(DictionaryService)
  dictionaryService: DictionaryService;

  @lazyInject(NutritionHistoryService)
  nutritionHistoryService: NutritionHistoryService;

  @lazyInject(CalculationController)
  calculationController: CalculationController;

  @lazyInject(CalculationStore)
  calculationStore: CalculationStore;

  @lazyInject(NutritionHistoryItemService)
  nutritionHistoryItemService: NutritionHistoryItemService;

  @lazyInject(InventoryValueStore)
  inventoryValueStore: InventoryValueStore;

  constructor() {
    makeAutoObservable(this);
  }

  fetchFertilizers = async (searchQuery: string, page?: number) => {
    const fertilizers = await this.dictionaryService.getDictionaryEntityList(
      {
        remoteName: 'fertilizers',
        attrs: {
          forAgriculturalUse: true,
        },
        nameFilter: searchQuery,
      },
      { size: 20, page }
    );

    return fertilizers;
  };

  fetchProtections = async (searchQuery: string, page?: number) => {
    const protections = await this.dictionaryService.getDictionaryEntityList(
      {
        remoteName: 'protection',
        nameFilter: searchQuery,
      },
      { size: 20, page }
    );

    return protections;
  };

  fetchProtectionMeasure = async (protectionId: string) => {
    const protectionMeasure = await this.dictionaryService.getDictionaryEntityList({
      remoteName: 'measure',
      dependencyName: 'protection',
      dependencyRecordId: protectionId,
    });

    this.inventoryValueStore.setMeasureInfo(protectionMeasure?.content?.[0]);

    return protectionMeasure?.content?.[0];
  };

  fetchFertilizersPrices = async (experimentId: string) => {
    const fertilizerPrices = await this.nutritionHistoryService.getFertilizerPrices({
      experimentId,
    });

    this.inventoryValueStore.setFertilizerPrices(fertilizerPrices);
  };

  fetchProtectionsPrices = async (experimentId: string) => {
    const protectionPrices = await this.nutritionHistoryService.getProtectionPrices({
      experimentId,
    });

    this.inventoryValueStore.setProtectionPrices(protectionPrices);
  };

  getInventoryValueOptionListFromItemsList = async (
    searchQuery: string,
    page?: number,
    isExecution?: boolean
  ) => {
    const { previousNameValue, formType } = this.inventoryValueStore;

    const { content, totalPages } = await this.fetchFertilizers(searchQuery, page);

    const currentExperimentStep = this.calculationStore.currentTechOperation;
    const currentNutritionHistory = this.calculationStore.getNutritionHistoryByExperimentStepId(
      currentExperimentStep?.id
    );

    const fertilizerIdListFromStoredNutritionHistory = isExecution
      ? []
      : currentNutritionHistory?.items?.map(item => item.fertilizer.id) ?? [];

    const storedCurrentFertilizerIdList = this.inventoryValueStore.currentFertilizerIdList;

    const formattedCurrentFertilizerIdList =
      storedCurrentFertilizerIdList?.length > 0
        ? storedCurrentFertilizerIdList
        : fertilizerIdListFromStoredNutritionHistory;

    const currentFertilizers = content.filter(
      item =>
        !formattedCurrentFertilizerIdList.includes(item.id) ||
        (formattedCurrentFertilizerIdList.includes(item.id) &&
          previousNameValue === item.id &&
          formType === 'edit')
    );

    return {
      fertilizerList: [createDictionaryEntitySelectOptionList(currentFertilizers)],
      totalPages,
    };
  };

  getInventoryValueOptionListFromProtectionsList = async (
    searchQuery: string,
    page?: number,
    isExecution?: boolean
  ) => {
    const { previousNameValue, formType } = this.inventoryValueStore;

    const { content, totalPages } = await this.fetchProtections(searchQuery, page);

    const currentExperimentStep = this.calculationStore.currentTechOperation;
    const currentNutritionHistory = this.calculationStore.getNutritionHistoryByExperimentStepId(
      currentExperimentStep?.id
    );

    const protectionIdListFromStoredNutritionHistory = isExecution
      ? []
      : currentNutritionHistory?.protectionItems?.map(item => item.protection.id) ?? [];

    const storedCurrentProtectionIdList = this.inventoryValueStore.currentProtectionIdList;

    const formattedCurrentProtectionIdList =
      storedCurrentProtectionIdList?.length > 0
        ? storedCurrentProtectionIdList
        : protectionIdListFromStoredNutritionHistory;

    const currentProtections = content.filter(
      item =>
        !formattedCurrentProtectionIdList.includes(item.id) ||
        (formattedCurrentProtectionIdList.includes(item.id) &&
          previousNameValue === item.id &&
          formType === 'edit')
    );

    return {
      protectionList: [createDictionaryEntitySelectOptionList(currentProtections)],
      totalPages,
    };
  };

  createInventoryValue = async (
    form: IInventoryValuesForm,
    experimentStep: IExperimentStep,
    cultureZone: IExperimentCultureZone,
    experimentId: string,
    unitOfMeasureId: string
  ) => {
    const inventoryInfo: ICreateNutritionHistoryItemDto = {
      concentration: toNumber(form.dosage),
      cultureZoneExperimentId: cultureZone.id,
      experimentStepId: experimentStep.id,
      pricePerUnit: toNumber(form.price),
      isFact: false,
      unitOfMeasureId,
    };

    if (form.typeValue === 'fertilizer') {
      inventoryInfo.fertilizerId = form.nameValue;
    } else if (form.typeValue === 'protection') {
      inventoryInfo.protectionId = form.nameValue;
    }

    if (form.typeValue === 'fertilizer') {
      await this.nutritionHistoryItemService.createNutritionItemHistory(inventoryInfo);
    } else if (form.typeValue === 'protection') {
      await this.nutritionHistoryItemService.createNutritionProtectionItemHistory(inventoryInfo);
    }

    Promise.allSettled([
      this.calculationController.fetchNutritionHistories(
        {
          experimentId,
          cultureZoneExperimentId: cultureZone.id,
          experimentStepType: EExperimentStepType.Plan,
        },
        cultureZone.type === EExperimentCultureZoneType.Control
      ),
      this.calculationController.fetchCultureZones({ experimentId }, cultureZone.id),
    ]);
  };

  fertilizersSearchQueryHandler = async (
    searchQuery: string,
    isExecutionPage?: boolean
  ): Promise<ISelectOption[]> => {
    const {
      setFertilizerCurrentPage,
      setFertilizerTotalPages,
      setFertilizeSearchQuery,
      setFertilizeOptionList,
    } = this.inventoryValueStore;

    const { fertilizerList, totalPages } = await this.getInventoryValueOptionListFromItemsList(
      searchQuery,
      0,
      isExecutionPage
    );

    const [fertilizers] = fertilizerList;

    /**
     * Изменение параметров для работы пагинации в дропдауне
     */
    setFertilizerCurrentPage(0);

    setFertilizerTotalPages(totalPages);

    setFertilizeOptionList(fertilizers);

    setFertilizeSearchQuery(searchQuery);

    return flatten(fertilizerList);
  };

  onFertilizeListScroll = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { fertilizerCurrentPage } = this.inventoryValueStore;

    const { fertilizerList } = await this.getInventoryValueOptionListFromItemsList(
      searchQuery,
      fertilizerCurrentPage
    );

    return flatten(fertilizerList);
  };

  protectionsSearchQueryHandler = async (
    searchQuery: string,
    isExecutionPage?: boolean
  ): Promise<ISelectOption[]> => {
    const {
      setProtectionCurrentPage,
      setProtectionTotalPages,
      setProtectionSearchQuery,
      setProtectionOptionList,
    } = this.inventoryValueStore;

    const { protectionList, totalPages } =
      await this.getInventoryValueOptionListFromProtectionsList(searchQuery, 0, isExecutionPage);

    const [protections] = protectionList;

    /**
     * Изменение параметров для работы пагинации в дропдауне
     */
    setProtectionCurrentPage(0);

    setProtectionTotalPages(totalPages);

    setProtectionOptionList(protections);

    setProtectionSearchQuery(searchQuery);

    return flatten(protectionList);
  };

  onProtectionListScroll = async (searchQuery: string): Promise<ISelectOption[]> => {
    const { protectionCurrentPage } = this.inventoryValueStore;

    const { protectionList } = await this.getInventoryValueOptionListFromProtectionsList(
      searchQuery,
      protectionCurrentPage
    );

    return flatten(protectionList);
  };

  updateFertilizerInventoryValue = async (
    id: string,
    form: IInventoryValuesForm,
    cultureZone: IExperimentCultureZone,
    experimentId: string,
    isNeedToDeleteOldAndCreateNew: boolean,
    previousTypeValue: string,
    previousNameValue: string
  ) => {
    if (isNeedToDeleteOldAndCreateNew) {
      const currentExperimentStep = this.calculationStore.currentTechOperation;

      if (previousTypeValue === 'fertilizer') {
        this.calculationController.deleteFertilizerInventoryValue(
          currentExperimentStep.id,
          previousNameValue,
          {
            experimentId,
            cultureZoneExperimentId: cultureZone.id,
          }
        );
      } else {
        this.calculationController.deleteProtectionInventoryValue(
          currentExperimentStep.id,
          previousNameValue,
          {
            experimentId,
            cultureZoneExperimentId: cultureZone.id,
          }
        );
      }

      const defaultMeasureKG = 'f357cb40-6d3a-11eb-8d42-dd19d7aaf478';

      this.createInventoryValue(
        form,
        currentExperimentStep,
        cultureZone,
        experimentId,
        defaultMeasureKG
      );
    } else {
      await this.nutritionHistoryItemService.updateNutritionItemHistory({
        nutritionHistoryItemId: id,
        concentration: toNumber(form.dosage),
        fertilizerId: form?.nameValue,
        pricePerUnit: toNumber(form.price),
      });
      Promise.allSettled([
        this.calculationController.fetchNutritionHistories({
          experimentId,
          cultureZoneExperimentId: cultureZone.id,
          experimentStepType: EExperimentStepType.Plan,
        }),
        this.calculationController.fetchCultureZones({ experimentId }, cultureZone.id),
      ]);
    }
  };

  updateProtectionInventoryValue = async (
    id: string,
    form: IInventoryValuesForm,
    cultureZone: IExperimentCultureZone,
    experimentId: string,
    isNeedToDeleteOldAndCreateNew: boolean,
    previousTypeValue: string,
    previousNameValue: string
  ) => {
    if (isNeedToDeleteOldAndCreateNew) {
      const currentExperimentStep = this.calculationStore.currentTechOperation;

      if (previousTypeValue === 'fertilizer') {
        this.calculationController.deleteFertilizerInventoryValue(
          currentExperimentStep.id,
          previousNameValue,
          {
            experimentId,
            cultureZoneExperimentId: cultureZone.id,
          }
        );
      } else {
        this.calculationController.deleteProtectionInventoryValue(
          currentExperimentStep.id,
          previousNameValue,
          {
            experimentId,
            cultureZoneExperimentId: cultureZone.id,
          }
        );
      }

      const measureInfo = this.inventoryValueStore.measureInfo;

      this.createInventoryValue(
        form,
        currentExperimentStep,
        cultureZone,
        experimentId,
        measureInfo?.id
      );
    } else {
      await this.nutritionHistoryItemService.updateNutritionProtectionItemHistory({
        nutritionHistoryProtectionItemId: id,
        concentration: toNumber(form.dosage),
        fertilizerId: form?.nameValue,
        pricePerUnit: toNumber(form.price),
      });
      Promise.allSettled([
        this.calculationController.fetchNutritionHistories({
          experimentId,
          cultureZoneExperimentId: cultureZone.id,
          experimentStepType: EExperimentStepType.Plan,
        }),
        this.calculationController.fetchCultureZones({ experimentId }, cultureZone.id),
      ]);
    }
  };

  changeFertilizerPageNumber = (): void => {
    const { fertilizerCurrentPage, setFertilizerCurrentPage } = this.inventoryValueStore;

    setFertilizerCurrentPage(fertilizerCurrentPage + 1);
  };

  changeProtectionPageNumber = (): void => {
    const { protectionCurrentPage, setProtectionCurrentPage } = this.inventoryValueStore;

    setProtectionCurrentPage(protectionCurrentPage + 1);
  };
}
