import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { ModalFooter, useModal } from '@farmlink/farmik-ui';

import { useForm } from '../../../../../../../../../../../common/features/form/utils/hooks';
import {
  createAgrochemicalFormConfig,
  ICreateAgrochemicalForm,
} from '../../config/forms/createAgrochemicalForm';
import { useStore } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { AgrochemicalController } from '../../mobx/controllers/Agrochemical.controller';
import { CalculationStore } from '../../mobx/store/Calculation/Calculation.store';
import { getAgrochemicalForm, getAgrochemicalOtions } from '../../helpers/getAgrochemicalForm';
import { formatDateToISO } from '../../../../../../../../../../../common/utils/helpers/dates';
import { useIsOverflow } from '../../../../../../../../../../../common/utils/hooks';
import { ISelectOption } from '../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';

import Styled from './CreateAgrochemicalModal.styles';

interface ICreateAxoModalProps {
  formType: 'edit' | 'add';
}

const CreateAxoModal = forwardRef<HTMLInputElement, ICreateAxoModalProps>(({ formType }, elRef) => {
  const [optionListsOfMethods, setOptionListsOfMethods] =
    useState<Record<string, ISelectOption<any>[]>>();

  const { closeModal } = useModal();

  useImperativeHandle(elRef, () => {
    return ref.current;
  });

  const ref = useRef();
  const isOverflow = useIsOverflow(ref);

  const form = useForm<ICreateAgrochemicalForm>(createAgrochemicalFormConfig.formKey);

  const { fetchOptionsListAho, createAgrochemicalValue } = useStore(AgrochemicalController);
  const { activeCultureZone, currentExamination, examinationAttributeValues } =
    useStore(CalculationStore);

  const {
    elements,
    formData,
    registerForm,
    addOptionList,
    submitForm,
    changeListOfFormValue,
    changeElementData,
  } = form;

  useEffect(() => {
    registerForm(createAgrochemicalFormConfig);

    (async () => {
      const { optionLists, types, mechanicalComposition } = await fetchOptionsListAho();

      setOptionListsOfMethods(optionLists);

      Object.entries(optionLists).forEach(([key, value]) => {
        addOptionList(key as keyof ICreateAgrochemicalForm, value);
      });

      if (optionLists?.mechanicalCompositionMethod?.length === 1) {
        changeListOfFormValue({
          mechanicalCompositionMethod: optionListsOfMethods.mechanicalCompositionMethod[0].value,
        });
      }

      addOptionList('mechanicalComposition', mechanicalComposition);

      addOptionList('type', types);
    })();
  }, []);

  const onSuccess = () => {
    submitForm(async forms => {
      forms.date = formatDateToISO(forms.date);
      await createAgrochemicalValue(forms, activeCultureZone);
      closeModal();
    });
  };

  const successTitle = useMemo(() => (formType === 'add' ? 'Добавить' : 'Сохранить'), [formType]);

  useEffect(() => {
    if (formType === 'edit') {
      const currentAgrochemical = getAgrochemicalForm(
        currentExamination,
        examinationAttributeValues
      );

      const currentAgrochemicalOptions = getAgrochemicalOtions(
        currentExamination,
        examinationAttributeValues
      );

      if (currentAgrochemical && currentAgrochemicalOptions) {
        addOptionList('type', [
          {
            label: currentAgrochemicalOptions?.type?.name,
            value: currentAgrochemicalOptions?.type?.id,
          },
        ]);

        addOptionList('mechanicalComposition', [
          {
            label: currentAgrochemicalOptions?.mechanicalComposition?.name,
            value: currentAgrochemicalOptions?.mechanicalComposition?.id,
          },
        ]);

        addOptionList('mechanicalCompositionMethod', [
          {
            label: currentAgrochemicalOptions?.mechanicalCompositionMethod?.name,
            value: currentAgrochemicalOptions?.mechanicalCompositionMethod?.id,
          },
        ]);

        changeListOfFormValue({
          ...currentAgrochemical,
        });
      }
    }
  }, [formType, currentExamination, examinationAttributeValues]);

  const {
    mechanicalCompositionMethod: MechanicalCompositionMethod,
    humusMethod: HumusMethod,
    phMethod: PhMethod,
    poMethod: PoMethod,
    koMethod: KoMethod,
    date: Date,
    humus: Humus,
    ko: Ko,
    ph: Ph,
    po: Po,
    mechanicalComposition: MechanicalComposition,
    type: Type,
  } = elements;

  useEffect(() => {
    changeElementData('humusMethod', { isRequired: Boolean(formData?.humus) });
  }, [formData?.humus]);

  useEffect(() => {
    changeElementData('phMethod', { isRequired: Boolean(formData?.ph) });
  }, [formData?.ph]);

  useEffect(() => {
    changeElementData('poMethod', { isRequired: Boolean(formData?.po) });
  }, [formData?.po]);

  useEffect(() => {
    changeElementData('koMethod', { isRequired: Boolean(formData?.ko) });
  }, [formData?.ko]);

  useEffect(() => {
    if (formData && !formData.humus) {
      changeListOfFormValue({ humusMethod: '' });
    } else if (formData?.humus && optionListsOfMethods?.humusMethod?.length === 1) {
      changeListOfFormValue({ humusMethod: optionListsOfMethods.humusMethod[0].value });
    }
  }, [formData?.humus]);

  useEffect(() => {
    if (formData && !formData.po) {
      changeListOfFormValue({ poMethod: '' });
    } else if (formData?.po && optionListsOfMethods?.poMethod?.length === 1) {
      changeListOfFormValue({ poMethod: optionListsOfMethods.poMethod[0].value });
    }
  }, [formData?.po]);

  useEffect(() => {
    if (formData && !formData.ph) {
      changeListOfFormValue({ phMethod: '' });
    } else if (formData?.ph && optionListsOfMethods?.phMethod?.length === 1) {
      changeListOfFormValue({ phMethod: optionListsOfMethods.phMethod[0].value });
    }
  }, [formData?.ph]);

  useEffect(() => {
    if (formData && !formData.ko) {
      changeListOfFormValue({ koMethod: '' });
    } else if (formData?.ko && optionListsOfMethods?.koMethod?.length === 1) {
      changeListOfFormValue({ koMethod: optionListsOfMethods.koMethod[0].value });
    }
  }, [formData?.ko]);

  return (
    <Styled.Wrapper>
      <Styled.ContentWrapper ref={ref}>
        <Styled.Attribute width="100%" marginBottom="24px">
          {Date && <Date />}
        </Styled.Attribute>

        <Styled.Attribute width="100%" marginBottom="24px">
          {Type && <Type />}
        </Styled.Attribute>

        <Styled.Attribute marginBottom="24px" marginRight="12px">
          {MechanicalComposition && <MechanicalComposition />}
        </Styled.Attribute>

        <Styled.Attribute>
          {MechanicalCompositionMethod && <MechanicalCompositionMethod />}
        </Styled.Attribute>

        <Styled.Attribute marginBottom="24px" marginRight="12px">
          {Humus && <Humus />}
        </Styled.Attribute>

        <Styled.Attribute>{HumusMethod && <HumusMethod />}</Styled.Attribute>

        <Styled.Attribute marginBottom="24px" marginRight="12px">
          {Ph && <Ph />}
        </Styled.Attribute>

        <Styled.Attribute>{PhMethod && <PhMethod />}</Styled.Attribute>

        <Styled.Attribute marginBottom="24px" marginRight="12px">
          {Po && <Po />}
        </Styled.Attribute>

        <Styled.Attribute>{PoMethod && <PoMethod />}</Styled.Attribute>

        <Styled.Attribute marginBottom="24px" marginRight="12px">
          {Ko && <Ko />}
        </Styled.Attribute>

        <Styled.Attribute>{KoMethod && <KoMethod />}</Styled.Attribute>
      </Styled.ContentWrapper>

      <Styled.FooterWrapper $isOverflow={isOverflow}>
        <ModalFooter
          successButton={{
            title: successTitle,
            handler: onSuccess,
          }}
          denyButton={{
            title: 'Отменить',
            handler: closeModal,
          }}
        />
      </Styled.FooterWrapper>
    </Styled.Wrapper>
  );
});

export default observer(CreateAxoModal);
