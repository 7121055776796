import { AutoTooltip } from '@farmlink/farmik-ui';
import {
  ChangeEvent,
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import minusDefaultSvg from '../Dropdown/assets/images/minus.svg';

import Styled from './styles';
import { TInputProps } from './types';

const MAXIMAL_LENGTH_DEFAULT = 100;

const Input = forwardRef<HTMLInputElement, TInputProps>(
  (
    {
      onChange,
      label,
      isRequired,
      className,
      isDisabled,
      isBlocked,
      forceScrollAllowed,
      value = '',
      placeholder,
      type,
      error,
      isWithoutErrorText,
      maxLength,
      dataTestId,
      id,
      onBlur,
      as = 'input',
      isResizable,
      height,
      hideScrollbar,
      pattern,
      onKeyPress,
      rows,
    },
    ref
  ) => {
    const [isEllipsis, setIsEllipsis] = useState(false);
    const refElement = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => {
      return refElement.current;
    });

    useEffect(() => {
      if (
        value?.toString().length > 0 &&
        as === 'input' &&
        refElement?.current?.scrollWidth &&
        refElement?.current?.clientWidth
      ) {
        setIsEllipsis(refElement?.current?.scrollWidth > refElement?.current?.clientWidth);
      } else {
        setIsEllipsis(false);
      }
    }, [value]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
      onChange?.(event.target.value.toString(), event);
    };

    return (
      <Styled.Wrapper data-test-id="input-wrapper">
        <Styled.TopBlock data-test-id="input-top-block">
          {label && (
            <Styled.LabelWrapper data-test-id="input-label-wrapper">
              <Styled.Label required={isRequired} data-test-id="input-label">
                {label}
              </Styled.Label>
            </Styled.LabelWrapper>
          )}
        </Styled.TopBlock>
        <AutoTooltip content={value} disabled={!isEllipsis}>
          <div>
            <Styled.InputField
              className={className}
              disabled={isDisabled || isBlocked}
              value={value}
              placeholder={placeholder}
              type={type}
              onChange={handleChange}
              onKeyPress={onKeyPress ? onKeyPress : () => null}
              maxLength={maxLength ? maxLength : MAXIMAL_LENGTH_DEFAULT}
              data-test-id={dataTestId}
              id={id}
              ref={refElement}
              onBlur={onBlur}
              as={as}
              pattern={pattern}
              autoComplete={'off'}
              rows={rows}
              $error={error}
              $isBlocked={isBlocked}
              $forceScrollAllowed={forceScrollAllowed}
              $isResizable={isResizable}
              $height={height}
              $hideScrollbar={hideScrollbar}
              $isDisabled={isDisabled}
              $as={as}
            />
          </div>
        </AutoTooltip>

        {isBlocked && as !== 'textarea' && (
          <Styled.IconWrapper
            $isLabel={Boolean(label)}
            $align={'end'}
            data-test-id="input-icon-wrapper"
          >
            <Styled.Minus $defaultSvg={minusDefaultSvg} data-test-id="input-icons-blocked-minus" />
          </Styled.IconWrapper>
        )}

        {error && !isWithoutErrorText && (
          <Styled.ErrorMessage data-test-id={`${dataTestId}_errorMessage`}>
            {error}
          </Styled.ErrorMessage>
        )}
      </Styled.Wrapper>
    );
  }
);

Input.displayName = 'Input';

export default memo(Input);
