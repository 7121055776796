import { FC, useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import { observer } from 'mobx-react';
import _ from 'lodash';

import { useForm } from '../../../../../../../../../../../common/features/form/utils/hooks';
import { useStore } from '../../../../../../../../../../../common/utils/helpers/mobx';
import {
  IInventoryValuesForm,
  createInventoryValuesFormConfig,
  INVENTORY_VALUES_FORM_KEY,
} from '../../config/forms/inventoryValuesForm';
import { CalculationStore } from '../../mobx/store/Calculation/Calculation.store';
import { InventoryValueController } from '../../mobx/controllers/InventoryValue.controller';
import { InventoryValueStore } from '../../mobx/store/Calculation/InventoryValue.store';
import { CreateExperimentStore } from '../../../../../../mobx/stores';
import { useIsOverflow } from '../../../../../../../../../../../common/utils/hooks';
import { ISelectOption } from '../../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { IFertilizerPrice } from '../../../../../../../../../../../../api/models/as-fields/plan/FertilizerPrice';
import { IProtectionPrice } from '../../../../../../../../../../../../api/models/as-fields/plan/ProtectionPrice';

import Styled from './InventoryValuesForm.style';

export interface IInventoryValuesFormModalPayload {
  experimentId: string;
  currentFertilizerIdList?: string[];
  currentProtectionIdList?: string[];
  onCreate?: (
    form: IInventoryValuesForm,
    selectedItem: ISelectOption,
    inventoryValueMeasureId?: string
  ) => string;
  isExecutionPage?: boolean;
}

interface IInventoryValuesFormProps {
  formType: 'add' | 'edit';
  inventoryValueType?: 'protection' | 'fertilizer';
}

const InventoryValuesForm: FC<IInventoryValuesFormProps> = ({ formType, inventoryValueType }) => {
  const [checked, setChecked] = useState(false);
  const [previousTypeValue, setPreviousTypeValue] = useState(null);
  const [isEdited, setIsEdited] = useState(false);

  const { selectedExp } = useStore(CreateExperimentStore);

  const ref = useRef();
  const isOverflow = useIsOverflow(ref);

  const form = useForm<IInventoryValuesForm>(INVENTORY_VALUES_FORM_KEY);
  const {
    elements,
    getElement,
    registerForm,
    formData,
    initialFormData,
    changeInitialFormValue,
    submitForm,
    addOptionList,
    changeListOfFormValue,
    blockElement,
    addPaginationConfig,
    changeElementLabel,
  } = form;

  const { closeModal, openModalByModalId, getModalPayload } = useModal();
  const modalPayload = (getModalPayload() || {}) as IInventoryValuesFormModalPayload;

  const currentExperimentId = useMemo(
    () => modalPayload?.experimentId ?? selectedExp?.id,
    [selectedExp, modalPayload?.experimentId]
  );

  const isFormChanged = useMemo(
    () => !_.isEqual(formData, initialFormData),
    [formData, initialFormData]
  );

  const isRequiredFieldsNotEmpty = useMemo(
    () => _.values(_.omit(formData, ['typeValue', 'price'])).every(item => Boolean(item)),
    [formData]
  );

  const { typeValue: Type, nameValue: Name, dosage: Dosage, price: Price } = elements;

  const {
    currentFertilizerInventoryValue,
    activeCultureZone,
    currentTechOperation,
    nutritionHistories,
    currentProtectionInventoryValue,
  } = useStore(CalculationStore);

  const {
    getPriceByFertilizerId,
    getPriceByProtectionId,
    fertilizerCurrentPage,
    fertilizerTotalPages,
    fertilizeSearchQuery,
    setFertilizeOptionList,
    setFertilizerCurrentPage,
    fertilizeOptionList,
    protectionCurrentPage,
    protectionTotalPages,
    protectionSearchQuery,
    setProtectionOptionList,
    setProtectionCurrentPage,
    protectionOptionList,
    fertilizerPrices,
    protectionPrices,
    setCurrentFertilizerIdList,
    setCurrentProtectionIdList,
    setCurrentFertilizerId,
    setCurrentProtectionId,
    clearCurrentFertilizerIdList,
    clearCurrentProtectionIdList,
    measureInfo,
    previousNameValue,
    setPreviousNameValue,
    setFormType,
  } = useStore(InventoryValueStore);

  const {
    getInventoryValueOptionListFromProtectionsList,
    getInventoryValueOptionListFromItemsList,
    createInventoryValue,
    updateFertilizerInventoryValue,
    updateProtectionInventoryValue,
    fetchFertilizersPrices,
    fetchProtectionsPrices,
    fertilizersSearchQueryHandler,
    protectionsSearchQueryHandler,
    changeProtectionPageNumber,
    changeFertilizerPageNumber,
    onFertilizeListScroll,
    onProtectionListScroll,
    fetchProtectionMeasure,
  } = useStore(InventoryValueController);

  useEffect(() => {
    if (modalPayload.currentFertilizerIdList) {
      setCurrentFertilizerIdList(modalPayload?.currentFertilizerIdList);
    }

    if (modalPayload.currentProtectionIdList) {
      setCurrentProtectionIdList(modalPayload?.currentProtectionIdList);
    }

    return () => {
      clearCurrentFertilizerIdList();
      clearCurrentProtectionIdList();
    };
  }, []);

  const onCreate = () => {
    submitForm(async forms => {
      const defaultMeasureKG = 'f357cb40-6d3a-11eb-8d42-dd19d7aaf478';

      if (modalPayload?.onCreate) {
        const selectedItemOptionList = getElement('nameValue')?.selectOptions?.optionList;
        const selectedItem = selectedItemOptionList.find(item => item.value === forms.nameValue);

        const createdItemByNutritionItemId = modalPayload.onCreate(
          forms,
          selectedItem,
          forms?.typeValue === 'fertilizer' ? defaultMeasureKG : measureInfo?.id
        );

        if (forms?.typeValue === 'fertilizer') {
          setCurrentFertilizerId(createdItemByNutritionItemId);
        } else if (forms?.typeValue === 'protection') {
          setCurrentProtectionId(createdItemByNutritionItemId);
        }
      } else {
        await createInventoryValue(
          forms,
          currentTechOperation,
          activeCultureZone,
          currentExperimentId,
          forms?.typeValue === 'fertilizer' ? defaultMeasureKG : measureInfo?.id
        );
      }

      if (checked) {
        openModalByModalId(`addPlanInventoryValues`);
        changeListOfFormValue({ typeValue: '', nameValue: '', dosage: '', price: '' });
        changeInitialFormValue({ typeValue: '', nameValue: '', dosage: '', price: '' });
        addOptionList('nameValue', []);
        setFertilizeOptionList([]);
        setChecked(false);
      } else {
        closeModal();
      }
    });
  };

  const isNeedToDeleteOldAndCreateNew = useMemo(
    () => formType === 'edit' && formData?.nameValue !== previousNameValue,
    [formData?.nameValue, formType, previousNameValue]
  );

  const onEdit = useCallback(() => {
    submitForm(async forms => {
      if (formData?.typeValue === 'fertilizer') {
        await updateFertilizerInventoryValue(
          currentFertilizerInventoryValue?.id || formData?.nameValue,
          forms,
          activeCultureZone,
          currentExperimentId,
          isNeedToDeleteOldAndCreateNew,
          previousTypeValue,
          previousNameValue
        );
      } else if (formData?.typeValue === 'protection') {
        await updateProtectionInventoryValue(
          currentProtectionInventoryValue?.id || formData?.nameValue,
          forms,
          activeCultureZone,
          currentExperimentId,
          isNeedToDeleteOldAndCreateNew,
          previousTypeValue,
          previousNameValue
        );
      }

      closeModal();
    });
  }, [
    activeCultureZone,
    currentExperimentId,
    formData?.typeValue,
    currentFertilizerInventoryValue,
    currentProtectionInventoryValue,
    isNeedToDeleteOldAndCreateNew,
  ]);

  useEffect(() => {
    switch (inventoryValueType) {
      case 'protection':
        registerForm(
          createInventoryValuesFormConfig({
            protectionsSearchQueryHandler,
            isExecutionPage: modalPayload.isExecutionPage,
          })
        );

        break;
      case 'fertilizer':
        registerForm(
          createInventoryValuesFormConfig({
            fertilizersSearchQueryHandler,
            isExecutionPage: modalPayload.isExecutionPage,
          })
        );

        break;
      default:
        registerForm(
          createInventoryValuesFormConfig({
            fertilizersSearchQueryHandler,
            isExecutionPage: modalPayload.isExecutionPage,
          })
        );

        break;
    }
  }, [inventoryValueType]);

  useEffect(() => {
    /**
     * To Do: заменить регистрирование новой формы на возможность менять querySearchHandler у селекта
     */
    if (formType === 'add' || (formType === 'edit' && isEdited)) {
      switch (formData?.typeValue) {
        case 'protection':
          registerForm(
            createInventoryValuesFormConfig({
              protectionsSearchQueryHandler,
              isExecutionPage: modalPayload.isExecutionPage,
            })
          );

          changeListOfFormValue({ typeValue: 'protection' });
          blockElement('nameValue', false);

          break;
        case 'fertilizer':
          registerForm(
            createInventoryValuesFormConfig({
              fertilizersSearchQueryHandler,
              isExecutionPage: modalPayload.isExecutionPage,
            })
          );

          changeListOfFormValue({ typeValue: 'fertilizer' });
          blockElement('nameValue', false);

          break;
        default:
          changeListOfFormValue({ nameValue: '' });
          blockElement('nameValue', true);

          break;
      }
    }

    if (formData?.typeValue && formData?.typeValue === previousTypeValue) {
      setIsEdited(true);
    }
  }, [formData?.typeValue]);

  useEffect(() => {
    (async () => {
      if (
        (formType === 'add' && formData?.typeValue === 'fertilizer') ||
        (formType === 'edit' && isEdited && formData?.typeValue === 'fertilizer')
      ) {
        /**
         * Получаем наименование тмц с пустым параметром query и 0 страницей
         */
        const { fertilizerList } = await getInventoryValueOptionListFromItemsList(
          '',
          0,
          modalPayload.isExecutionPage
        );

        const [fertilizer] = fertilizerList;

        /**
         * Запоминаем лист опций на будущее, если его придется копировать
         */
        setFertilizeOptionList(fertilizer);

        /**
         * Меняем начальную страницу данных на 0
         */
        setFertilizerCurrentPage(0);

        addOptionList('nameValue', fertilizer);
      } else if (
        (formType === 'add' && formData?.typeValue === 'protection') ||
        (formType === 'edit' && isEdited && formData?.typeValue === 'protection')
      ) {
        /**
         * Получаем наименование тмц с пустым параметром query и 0 страницей
         */
        const { protectionList } = await getInventoryValueOptionListFromProtectionsList(
          '',
          0,
          modalPayload.isExecutionPage
        );

        const [protection] = protectionList;

        /**
         * Запоминаем лист опций на будущее, если его придется копировать
         */
        setProtectionOptionList(protection);

        /**
         * Меняем начальную страницу данных на 0
         */
        setProtectionCurrentPage(0);

        addOptionList('nameValue', protection);
      }
    })();
  }, [nutritionHistories, formData?.typeValue]);

  const onFertilizeScroll = async () => {
    const fertilizers = await onFertilizeListScroll(fertilizeSearchQuery);

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('nameValue', [...fertilizeOptionList, ...fertilizers]);

    setFertilizeOptionList([...fertilizeOptionList, ...fertilizers]);
  };

  const onProtectionScroll = async () => {
    const protections = await onProtectionListScroll(protectionSearchQuery);

    /**
     * Добавляем новый лист опций к старому при скролле
     */
    addOptionList('nameValue', [...protectionOptionList, ...protections]);

    setProtectionOptionList([...protectionOptionList, ...protections]);
  };

  useEffect(() => {
    if (formData?.typeValue === 'fertilizer') {
      addPaginationConfig('nameValue', {
        currentPage: fertilizerCurrentPage,
        totalPages: fertilizerTotalPages,
        onScroll: onFertilizeScroll,
        onPageChange: changeFertilizerPageNumber,
      });
    }
  }, [fertilizerCurrentPage, fertilizerTotalPages, onFertilizeScroll, changeFertilizerPageNumber]);

  useEffect(() => {
    if (formData?.typeValue === 'protection') {
      addPaginationConfig('nameValue', {
        currentPage: protectionCurrentPage,
        totalPages: protectionTotalPages,
        onScroll: onProtectionScroll,
        onPageChange: changeProtectionPageNumber,
      });
    }
  }, [protectionCurrentPage, protectionTotalPages, onProtectionScroll, changeProtectionPageNumber]);

  useEffect(() => {
    fetchFertilizersPrices(currentExperimentId);
    fetchProtectionsPrices(currentExperimentId);
  }, [currentExperimentId]);

  useEffect(() => {
    if (formData?.nameValue) {
      blockElement('dosage', false);
      blockElement('price', false);
      blockElement('nameValue', false);

      let fertilizerPrice: IFertilizerPrice | IProtectionPrice | null = null;

      if (formData?.typeValue === 'fertilizer') {
        fertilizerPrice = getPriceByFertilizerId(formData.nameValue);
      } else if (formData?.typeValue === 'protection') {
        fertilizerPrice = getPriceByProtectionId(formData.nameValue);
      }

      if (fertilizerPrice && !modalPayload.isExecutionPage) {
        changeListOfFormValue({ price: String(fertilizerPrice.pricePerUnit) });
      } else if (!modalPayload.isExecutionPage) {
        changeListOfFormValue({ price: '' });
      }

      if (formData?.typeValue === 'fertilizer') {
        changeElementLabel('dosage', 'Дозировка, кг/га');

        if (modalPayload.isExecutionPage) {
          changeElementLabel('price', 'Цена ТМЦ, Р/кг. Применяется для всего опыта');
        } else {
          changeElementLabel('price', 'Цена ТМЦ, Р/кг.');
        }
      }

      if (formData?.typeValue === 'protection') {
        (async () => {
          const measureContent = await fetchProtectionMeasure(formData?.nameValue);

          changeElementLabel('dosage', `Дозировка, ${measureContent?.name}/га`);

          if (modalPayload.isExecutionPage) {
            changeElementLabel(
              'price',
              `Цена ТМЦ, Р/${measureContent?.name}. Применяется для всего опыта`
            );
          } else {
            changeElementLabel('price', `Цена ТМЦ, Р/${measureContent?.name}.`);
          }
        })();
      }
    } else {
      blockElement('dosage', true);
      blockElement('price', true);
      changeListOfFormValue({ dosage: '' });
      changeListOfFormValue({ price: '' });

      changeElementLabel('dosage', 'Дозировка');

      if (modalPayload.isExecutionPage) {
        changeElementLabel('price', 'Цена ТМЦ. Применяется для всего опыта');
      } else {
        changeElementLabel('price', 'Цена ТМЦ');
      }
    }
  }, [formData?.nameValue, fertilizerPrices, protectionPrices]);

  useEffect(() => {
    setFormType(formType);

    if (formType === 'edit') {
      blockElement('dosage', false);
      blockElement('price', false);

      /**
       * Когда режим редактирования, то опшен для поля "Наименование ТМЦ" явно
       * хардкодим из полученной модели. Это необходимо для того, чтобы правильно
       * отобразить наименование без лишних запросов на бэк.
       */
      if (currentFertilizerInventoryValue?.fertilizer && inventoryValueType === 'fertilizer') {
        addOptionList('nameValue', [
          {
            label: currentFertilizerInventoryValue?.fertilizer?.name,
            value: currentFertilizerInventoryValue?.fertilizer?.id,
          },
        ]);

        setPreviousNameValue(currentFertilizerInventoryValue?.fertilizer?.id);
        setPreviousTypeValue('fertilizer');

        changeListOfFormValue({ typeValue: 'fertilizer' });
      } else if (
        currentProtectionInventoryValue?.protection &&
        inventoryValueType === 'protection'
      ) {
        addOptionList('nameValue', [
          {
            label: currentProtectionInventoryValue?.protection?.name,
            value: currentProtectionInventoryValue?.protection?.id,
          },
        ]);

        setPreviousNameValue(currentProtectionInventoryValue?.protection?.id);
        setPreviousTypeValue('protection');

        changeListOfFormValue({ typeValue: 'protection' });
      }

      if (currentFertilizerInventoryValue && inventoryValueType === 'fertilizer') {
        changeListOfFormValue({
          dosage: currentFertilizerInventoryValue.concentration
            ? String(currentFertilizerInventoryValue.concentration)
            : '',
          nameValue: currentFertilizerInventoryValue.fertilizer.id,
          price: currentFertilizerInventoryValue.pricePerUnit
            ? String(currentFertilizerInventoryValue.pricePerUnit)
            : '',
        });
        changeInitialFormValue({
          dosage: currentFertilizerInventoryValue.concentration
            ? String(currentFertilizerInventoryValue.concentration)
            : '',
          nameValue: currentFertilizerInventoryValue.fertilizer.id,
          price: currentFertilizerInventoryValue.pricePerUnit
            ? String(currentFertilizerInventoryValue.pricePerUnit)
            : '',
        });
      } else if (currentProtectionInventoryValue && inventoryValueType === 'protection') {
        changeListOfFormValue({
          dosage: currentProtectionInventoryValue.concentration
            ? String(currentProtectionInventoryValue.concentration)
            : '',
          nameValue: currentProtectionInventoryValue.protection.id,
          price: currentProtectionInventoryValue.pricePerUnit
            ? String(currentProtectionInventoryValue.pricePerUnit)
            : '',
        });
        changeInitialFormValue({
          dosage: currentProtectionInventoryValue.concentration
            ? String(currentProtectionInventoryValue.concentration)
            : '',
          nameValue: currentProtectionInventoryValue.protection.id,
          price: currentProtectionInventoryValue.pricePerUnit
            ? String(currentProtectionInventoryValue.pricePerUnit)
            : '',
        });
      }
    }
  }, [
    formType,
    inventoryValueType,
    currentProtectionInventoryValue,
    currentFertilizerInventoryValue,
  ]);

  const successButton = useMemo(() => {
    return {
      title: 'Сохранить',
      handler: formType === 'add' ? onCreate : onEdit,
      disabled: formType === 'add' ? !isRequiredFieldsNotEmpty : !isFormChanged,
    };
  }, [formType, isFormChanged, isRequiredFieldsNotEmpty, onCreate, onEdit]);

  const denyButton = useMemo(() => {
    return { title: 'Отменить', handler: closeModal };
  }, [closeModal]);

  return (
    <Styled.Wrapper>
      <Styled.ContentWrapper ref={ref}>
        <Styled.Attribute width="100%" marginBottom="24px">
          {Type && <Type />}
        </Styled.Attribute>
        <Styled.Attribute width="100%" marginBottom="24px">
          {Name && <Name />}
        </Styled.Attribute>
        <Styled.Attribute width="100%" marginBottom="24px">
          {Dosage && <Dosage />}
        </Styled.Attribute>
        <Styled.Attribute width="100%" marginBottom="24px">
          {Price && <Price />}
        </Styled.Attribute>

        {formType === 'add' && (
          <Styled.CheckboxAttribute>
            <Styled.Checkbox value={checked} label="Добавить еще одно ТМЦ" onChange={setChecked} />
          </Styled.CheckboxAttribute>
        )}
      </Styled.ContentWrapper>

      <Styled.FooterWrapper $isOverflow={isOverflow}>
        <ModalFooter successButton={successButton} denyButton={denyButton} />
      </Styled.FooterWrapper>
    </Styled.Wrapper>
  );
};

export default observer(InventoryValuesForm);
