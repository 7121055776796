import { lazyInject, provide } from '../../../../../../../../../common/utils/helpers/mobx';
import {
  ExperimentService,
  ExperimentStepsService,
  TasksService,
} from '../../../../../../../../../common/mobx/services/as-fields';
import {
  IExperimentCultureZone,
  IExperimentStep,
} from '../../../../../../../../../../api/models/as-fields/experiments';
import { AuditStore } from '../../../../mobx/store';
import { ITask } from '../../../../../../../../../../api/models/as-fields/task/Task';
import { AuditReportsHelpers } from '../../utils/helpers';
import { TGetMonitoringReportDataRes } from '../../../../../../../../../../api';
import { IAuditReportForm } from '../../containers/AuditReportCommentSection/forms/config/auditReportFormConfig';
import { EChecklistType } from '../../../../../../../../../../api/models/as-fields/checklists/Checklist/Checklist.model';
import AuditReportStore from '../stores/AuditReport.store';
import { AuditReportTabsService } from '../services';

@provide.transient()
class AuditReportController {
  @lazyInject(AuditStore)
  protected auditStore: AuditStore;

  @lazyInject(AuditReportStore)
  protected auditReportStore: AuditReportStore;

  @lazyInject(ExperimentService)
  protected experimentService: ExperimentService;

  @lazyInject(AuditReportTabsService)
  protected tabsService: AuditReportTabsService;

  @lazyInject(TasksService)
  protected tasksService: TasksService;

  @lazyInject(ExperimentStepsService)
  protected experimentStepsService: ExperimentStepsService;

  fetchExpZoneList = async (): Promise<IExperimentCultureZone[]> => {
    const { selectedAudit } = this.auditStore;
    const { getCultureZoneList } = this.experimentService;

    const zoneList = await getCultureZoneList({ experimentId: selectedAudit.experiment.id });

    if (zoneList) {
      return zoneList;
    }
  };

  getAvailibleTabList = (): void => {
    this.tabsService.addAvailableTabList();
  };

  fetchTaskList = async (): Promise<ITask[]> => {
    const { selectedExp, selectedAudit } = this.auditStore;
    const { getTaskList } = this.tasksService;

    const response = await getTaskList({
      experimentId: selectedExp.id,
      organizationId: selectedExp.organization.id,
      seasonYear: selectedExp.seasonYear,
      experimentStepId: selectedAudit.id,
      size: 2000,
    });

    if (response?.content) {
      return response.content;
    }
  };

  fetchExperimentStepData = async (stepId: string): Promise<IExperimentStep> => {
    const { getExperimentStep } = this.experimentStepsService;

    const experimentStepData = await getExperimentStep({ id: stepId });

    return experimentStepData;
  };

  fetchMonitoringReportData = async (stepId: string): Promise<TGetMonitoringReportDataRes> => {
    const { getMonitoringReportData } = this.experimentStepsService;

    const reportData = await getMonitoringReportData({
      id: stepId,
      checklistType: this.auditReportStore.selectedTabId,
    });

    if (reportData) {
      return reportData;
    }
  };

  changeTab = (tabId: EChecklistType): void => {
    this.auditReportStore.setSelectedTabId(tabId);

    this.auditReportStore.clearDynamicTableConfig();

    this.getDynamicTableConfig();
  };

  getDynamicTableConfig = async () => {
    this.auditReportStore.setIsLoading(true);

    const { createCompleteInstList, createDynamicTableConfig, createInstanceList, addSystemStage } =
      AuditReportsHelpers;

    const { selectedAudit } = this.auditStore;
    const { selectedTabId } = this.auditReportStore;

    const reportData = await this.fetchMonitoringReportData(selectedAudit.id);

    // Получаем список КЗ для опыта и список задач
    const [expZoneList, taskList] = await Promise.all([
      this.fetchExpZoneList(),
      this.fetchTaskList(),
    ]);

    // Фильтруем задачи и оставляем только завершенные
    const filteredTaskList = taskList.filter(({ status }) => status === 'COMPLETED');

    // Создаем конфиг таблицы
    const dynamicTableConfig = createDynamicTableConfig(
      'auditsAuditReport',
      reportData.table.stages
    );

    // Фильтруем зоны и оставляем только те, у которых есть завершенные задачи
    const filteredExpZoneList = expZoneList.filter(zone => {
      return filteredTaskList.find(({ cultureZone }) => {
        return zone.cultureZone.id === cultureZone.id;
      });
    });

    // Сортируем зоны по id варианта опыта ExperimentZones
    const sortedExpZoneList = [];

    reportData.experimentZones.forEach(reportZone => {
      const foundExpZone = filteredExpZoneList.find(expZone => {
        const hasTheSameName = reportZone.id === expZone.id;

        return hasTheSameName;
      });

      if (!foundExpZone) return;

      sortedExpZoneList.push(foundExpZone);
    });

    // Создаем сопоставленный список всех опытных КЗ с агрегациями, задачами и т.п.
    const completeAggregateInstList = createCompleteInstList(
      sortedExpZoneList,
      taskList,
      reportData
    );

    // Создаем значения и колонки с КЗ для таблицы
    dynamicTableConfig.instances = createInstanceList(completeAggregateInstList, selectedTabId);
    dynamicTableConfig.headColumn.stages.push(addSystemStage());

    this.auditReportStore.setDynamicTableConfig(dynamicTableConfig);

    this.auditReportStore.setIsLoading(false);
  };

  updateExperimentStep = (stepId: string, stepData: IAuditReportForm): void => {
    const { updateExperimentStep } = this.experimentStepsService;

    updateExperimentStep({ experimentStepId: stepId, ...stepData });
  };
}

export default AuditReportController;
