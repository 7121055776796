import _ from 'lodash';

import { lazyInject, provide } from '../../../../../../../../../../common/utils/helpers/mobx';
import { ExperimentAttributesStore } from '../../stores';
import { ExperimentAttributesService } from '../../services';
import {
  IExperimentAttributesForm,
  IExperimentAttributesFormForSave,
} from '../../../forms/config/attributesFormConfig';
import { TChangeExperimentReq, TCreateExperimentReq } from '../../../../../../../../../../../api';
import {
  EExperimentStatus,
  EExperimentsWizardProgress,
  IExperiment,
  IExperimentContract,
} from '../../../../../../../../../../../api/models/as-fields/experiments';
import { ISelectOption } from '../../../../../../../../../../common/components/form/Dropdown/Dropdown.types';
import { ExperimentAttributesHelpers } from '../../../utils/helpers';
import { IOrganization } from '../../../../../../../../../../../api/models/da-profile/organizations/Organization.model';
import { IEmployee, IUser } from '../../../../../../../../../../../api/models/da-profile/users';
import { IDictionaryEntity } from '../../../../../../../../../../../api/models/da-dictionary/dictionary';
import { IShowErrorModal } from '../../../../../../../../../../common/utils/hooks/useErrorModal';
import {
  OrganizationsService,
  UsersService,
} from '../../../../../../../../../../common/mobx/services/da-profile';
import { DictionaryService } from '../../../../../../../../../../common/mobx/services/da-dictionary';
import {
  ExperimentService,
  GeoUnitsService,
} from '../../../../../../../../../../common/mobx/services/as-fields';
import {
  createDictionaryEntitySelectOptionList,
  createOrganizationSelectOptionList,
} from '../../../../../../../../../../common/utils/helpers/selectOptions';
import { CreateExperimentStore } from '../../../../../mobx/stores';
import { CreateEditContractService } from '../../../../../../../../contracts/modules/CreateEditContract/mobx/services';

@provide.singleton()
class ExperimentAttributesController {
  @lazyInject(CreateExperimentStore)
  protected createExpStore: CreateExperimentStore;

  @lazyInject(ExperimentAttributesStore)
  protected experimentAttributesStore: ExperimentAttributesStore;

  @lazyInject(ExperimentAttributesService)
  protected experimentAttributesService: ExperimentAttributesService;

  @lazyInject(OrganizationsService)
  protected organizationsService: OrganizationsService;

  @lazyInject(ExperimentService)
  protected experimentService: ExperimentService;

  @lazyInject(DictionaryService)
  protected dictionaryService: DictionaryService;

  @lazyInject(GeoUnitsService)
  protected geoUnitsService: GeoUnitsService;

  @lazyInject(UsersService)
  protected usersService: UsersService;

  @lazyInject(CreateEditContractService)
  protected createEditContractService: CreateEditContractService;

  fetchContractor = async (organizationId: string): Promise<ISelectOption[]> => {
    const { setContractor } = this.experimentAttributesStore;
    const { getOrganization } = this.organizationsService;

    if (!organizationId) {
      return [];
    }

    const createdData = await getOrganization({ organizationId }, { isCreateSelectOptions: true });

    if (createdData?.data) {
      setContractor(createdData.data);

      return createdData.selectOptionList;
    }
  };

  createPayloadToChangeExperiment = (
    form: IExperimentAttributesFormForSave
  ): TChangeExperimentReq => {
    const { selectedExp } = this.createExpStore;

    const selectedExperimentEntries = Object.entries(selectedExp);
    const changedDataEntries = Object.entries(form);

    const keyToValue = new Map();

    changedDataEntries.forEach(([key, value]) => {
      keyToValue.set(key, value);
    });

    changedDataEntries.forEach(([keyOne, valueOne]) => {
      selectedExperimentEntries.forEach(([keyTwo, valueTwo]) => {
        if (keyOne === keyTwo && valueOne === valueTwo) {
          keyToValue.delete(keyOne);
        }
      });
    });

    if (selectedExp.wizardProgress !== 'ATTRIBUTES') {
      keyToValue.delete('organizationId');
      keyToValue.delete('cultureId');
    }

    return { ...Object.fromEntries(Array.from(keyToValue.entries())), id: selectedExp.id };
  };

  createExperiment = async (
    form: IExperimentAttributesForm,
    challengeList: Array<string>,
    showErrorHandler?: IShowErrorModal
  ): Promise<IExperiment> => {
    const { createExperiment } = this.experimentAttributesService;
    const { selectedExp } = this.createExpStore;
    const { changeExperiment } = this.experimentService;

    const formValues = {
      ...form,
      challenges: challengeList.filter(challenge => challenge !== '') || [],
    };

    const payload: TCreateExperimentReq = {
      ...formValues,
      status: EExperimentStatus.Draft,
      wizardProgress: EExperimentsWizardProgress.Attributes,
    };

    const createdExperiment = selectedExp
      ? await changeExperiment(this.createPayloadToChangeExperiment(formValues), showErrorHandler)
      : await createExperiment(payload, showErrorHandler);

    return createdExperiment;
  };

  changeContractor = async (contractorId: string): Promise<void> => {
    const { changeContractById } = this.createEditContractService;
    const { selectedExp } = this.createExpStore;

    await changeContractById({ experimentId: selectedExp.id, contractId: contractorId });
  };

  createContractorOptionList = (contractorList: IOrganization[]): ISelectOption[] => {
    const { setContractorList } = this.experimentAttributesStore;

    if (!_.isArray(contractorList)) {
      return [];
    }

    setContractorList(contractorList);

    return createOrganizationSelectOptionList(contractorList);
  };

  createContractOptionList = (contractList: IExperimentContract[]): ISelectOption[] => {
    const { setContractList } = this.experimentAttributesStore;
    const { createContractOptionList } = ExperimentAttributesHelpers;

    if (!_.isArray(contractList)) {
      return [];
    }

    setContractList(contractList);

    return createContractOptionList(contractList);
  };

  createEmployeeOptionList = (employeeList: IEmployee[]): ISelectOption[] => {
    const { setEmployeeList } = this.experimentAttributesStore;
    const { createEmployeeOptionList } = ExperimentAttributesHelpers;

    if (!_.isArray(employeeList)) {
      return [];
    }

    setEmployeeList(employeeList);

    return createEmployeeOptionList(employeeList);
  };

  createExperimentUserOptionList = (userList: IUser[]): ISelectOption[] => {
    const { setExperimentUserList } = this.experimentAttributesStore;
    const { createUserOptionList } = ExperimentAttributesHelpers;

    if (!_.isArray(userList)) {
      return [];
    }

    setExperimentUserList(userList);

    return createUserOptionList(userList);
  };

  createSeasonOptionList = async (organizationId: string): Promise<ISelectOption[]> => {
    const { setSeasonList } = this.experimentAttributesStore;
    const { getSeasonList } = this.experimentAttributesService;

    const { createSeasonOptionList } = ExperimentAttributesHelpers;

    if (!organizationId) {
      return [];
    }

    const seasonList = await getSeasonList(organizationId);

    setSeasonList(seasonList);

    return createSeasonOptionList(seasonList);
  };

  createCultureOptionList = (cultureList: IDictionaryEntity[]): ISelectOption[] => {
    const { setCultureList } = this.experimentAttributesStore;
    const { createDictionaryEntityOptionList } = ExperimentAttributesHelpers;

    if (!_.isArray(cultureList)) {
      return [];
    }

    setCultureList(cultureList);

    return createDictionaryEntityOptionList(cultureList);
  };

  contractorSearchQueryHandler = async (searchQueryValue: string): Promise<ISelectOption[]> => {
    const { getContractorList } = this.experimentAttributesService;
    const { setDropdownPageNumbers, setOrganizationOptionList, setDropdownSearchQuery } =
      this.experimentAttributesStore;

    const { content, totalPages } = await getContractorList({
      nameOrInn: searchQueryValue,
      size: 20,
    });

    if (_.isArray(content)) {
      const contractorOptionList = this.createContractorOptionList(content);

      /**
       * Изменение параметров для работы пагинации в дропдауне
       */
      setDropdownPageNumbers('organizationCurrentPageNumber', 0);

      setDropdownPageNumbers('organizationTotalPageNumber', totalPages);

      setOrganizationOptionList(contractorOptionList);

      setDropdownSearchQuery('organizationSearchQuery', searchQueryValue);

      return contractorOptionList;
    }
  };

  onOrganizationListScroll = async (searchQueryValue: string): Promise<ISelectOption[]> => {
    const { getContractorList } = this.experimentAttributesService;
    const { dropdownPageNumbers } = this.experimentAttributesStore;

    const { content } = await getContractorList({
      nameOrInn: searchQueryValue,
      page: dropdownPageNumbers.organizationCurrentPageNumber,
      size: 20,
    });

    if (_.isArray(content)) {
      return this.createContractorOptionList(content);
    }
  };

  employeeSearchQueryHandler = async (searchQueryValue: string): Promise<ISelectOption[]> => {
    const {
      selectedContractor,
      setDropdownPageNumbers,
      setResponsibleOptionList,
      setDropdownSearchQuery,
    } = this.experimentAttributesStore;
    const { getEmployeeList } = this.experimentAttributesService;

    if (!selectedContractor) {
      return [];
    }

    const { content, totalPages } = await getEmployeeList({
      fullName: searchQueryValue,
      organizationID: selectedContractor?.organizationId,
      size: 20,
    });

    if (_.isArray(content)) {
      const employeeOptionList = this.createEmployeeOptionList(content);

      /**
       * Изменение параметров для работы пагинации в дропдауне
       */
      setDropdownPageNumbers('responsibleCurrentPageNumber', 0);

      setDropdownPageNumbers('responsibleTotalPageNumber', totalPages);

      setResponsibleOptionList(employeeOptionList);

      setDropdownSearchQuery('responsibleSearchQuery', searchQueryValue);

      return employeeOptionList;
    }
  };

  onResponsibleListScroll = async (searchQueryValue: string): Promise<ISelectOption[]> => {
    const { getEmployeeList } = this.experimentAttributesService;
    const { dropdownPageNumbers, selectedContractor } = this.experimentAttributesStore;

    const { content } = await getEmployeeList({
      fullName: searchQueryValue,
      organizationID: selectedContractor?.organizationId,
      size: 20,
      page: dropdownPageNumbers.responsibleCurrentPageNumber,
    });

    if (_.isArray(content)) {
      return this.createEmployeeOptionList(content);
    }
  };

  regionSearchQueryHandler = async (searchQueryValue: string): Promise<ISelectOption[]> => {
    const { getDictionaryEntityList } = this.experimentAttributesService;
    const { setDropdownPageNumbers, setRegionOptionList, setDropdownSearchQuery } =
      this.experimentAttributesStore;

    const { content, totalPages } = await getDictionaryEntityList(
      {
        remoteName: 'regions',
        level: 3,
        latestVersion: true,
        nameFilter: searchQueryValue,
      },
      { size: 20 }
    );

    if (_.isArray(content)) {
      const regionOptionList = createDictionaryEntitySelectOptionList(content);

      /**
       * Изменение параметров для работы пагинации в дропдауне
       */
      setDropdownPageNumbers('regionCurrentPageNumber', 0);

      setDropdownPageNumbers('regionTotalPageNumber', totalPages);

      setRegionOptionList(regionOptionList);

      setDropdownSearchQuery('regionQuerySearchQuery', searchQueryValue);

      return regionOptionList;
    }
  };

  onRegionListScroll = async (searchQueryValue: string): Promise<ISelectOption[]> => {
    const { getDictionaryEntityList } = this.experimentAttributesService;
    const { dropdownPageNumbers } = this.experimentAttributesStore;

    const { content } = await getDictionaryEntityList(
      {
        remoteName: 'regions',
        level: 3,
        latestVersion: true,
        nameFilter: searchQueryValue,
      },
      { size: 20, page: dropdownPageNumbers.regionCurrentPageNumber }
    );

    if (_.isArray(content)) {
      return createDictionaryEntitySelectOptionList(content);
    }
  };

  experimentUserSearchQueryHandler = async (searchQueryValue: string): Promise<ISelectOption[]> => {
    const { getExperimentUserList } = this.experimentAttributesService;
    const { setDropdownPageNumbers, setAssigneeOptionList, setDropdownSearchQuery } =
      this.experimentAttributesStore;

    const { content, totalPages } = await getExperimentUserList({
      fullName: searchQueryValue,
      size: 20,
    });

    if (_.isArray(content)) {
      const experimentUserList = this.createExperimentUserOptionList(content);

      /**
       * Изменение параметров для работы пагинации в дропдауне
       */
      setDropdownPageNumbers('assigneeCurrentPageNumber', 0);

      setDropdownPageNumbers('assigneeTotalPageNumber', totalPages);

      setAssigneeOptionList(experimentUserList);

      setDropdownSearchQuery('assigneeSearchQuery', searchQueryValue);

      return experimentUserList;
    }
  };

  onAssigneeListScroll = async (searchQueryValue: string): Promise<ISelectOption[]> => {
    const { getExperimentUserList } = this.experimentAttributesService;
    const { dropdownPageNumbers } = this.experimentAttributesStore;

    const { content } = await getExperimentUserList({
      fullName: searchQueryValue,
      size: 20,
      page: dropdownPageNumbers.assigneeCurrentPageNumber,
    });

    if (_.isArray(content)) {
      return this.createExperimentUserOptionList(content);
    }
  };

  contractSearchQueryHandler = async (searchQueryValue: string): Promise<ISelectOption[]> => {
    const {
      selectedContractor,
      setDropdownPageNumbers,
      setContractOptionList,
      setDropdownSearchQuery,
    } = this.experimentAttributesStore;
    const { getContractList } = this.experimentAttributesService;

    if (!selectedContractor) {
      return [];
    }

    const { content, totalPages } = await getContractList({
      organizationId: selectedContractor?.organizationId,
      name: searchQueryValue,
      size: 20,
    });

    if (_.isArray(content)) {
      const contractList = this.createContractOptionList(content);

      /**
       * Изменение параметров для работы пагинации в дропдауне
       */
      setDropdownPageNumbers('contractCurrentPageNumber', 0);

      setDropdownPageNumbers('contractTotalPageNumber', totalPages);

      setContractOptionList(contractList);

      setDropdownSearchQuery('contractSearchQuery', searchQueryValue);

      return contractList;
    }
  };

  onContractListScroll = async (searchQueryValue: string): Promise<ISelectOption[]> => {
    const { selectedContractor, dropdownPageNumbers } = this.experimentAttributesStore;
    const { getContractList } = this.experimentAttributesService;

    const { content } = await getContractList({
      organizationId: selectedContractor?.organizationId,
      name: searchQueryValue,
      size: 20,
      page: dropdownPageNumbers.contractCurrentPageNumber,
    });

    if (_.isArray(content)) {
      return this.createContractOptionList(content);
    }
  };

  cultureSearchQueryHandler = async (searchQueryValue: string): Promise<ISelectOption[]> => {
    const { getDictionaryEntityList } = this.experimentAttributesService;
    const { setDropdownPageNumbers, setCultureOptionList, setDropdownSearchQuery } =
      this.experimentAttributesStore;

    const { content, totalPages } = await getDictionaryEntityList(
      {
        remoteName: 'culture',
        attrs: { useInAssistance: true },
        latestVersion: true,
        nameFilter: searchQueryValue,
      },
      { size: 20 }
    );

    if (_.isArray(content)) {
      const cultureList = this.createCultureOptionList(content);

      /**
       * Изменение параметров для работы пагинации в дропдауне
       */
      setDropdownPageNumbers('cultureCurrentPageNumber', 0);

      setDropdownPageNumbers('cultureTotalPageNumber', totalPages);

      setCultureOptionList(cultureList);

      setDropdownSearchQuery('cultureSearchQuery', searchQueryValue);

      return cultureList;
    }
  };

  onCultureListScroll = async (searchQueryValue: string): Promise<ISelectOption[]> => {
    const { getDictionaryEntityList } = this.experimentAttributesService;
    const { dropdownPageNumbers } = this.experimentAttributesStore;

    const { content } = await getDictionaryEntityList(
      {
        remoteName: 'culture',
        attrs: { useInAssistance: true },
        latestVersion: true,
        nameFilter: searchQueryValue,
      },
      { size: 20, page: dropdownPageNumbers.cultureCurrentPageNumber }
    );

    if (_.isArray(content)) {
      return this.createCultureOptionList(content);
    }
  };

  changeDataAfterContractorChange = (organizationId: string): void => {
    const { getContractor, setSelectedContractor, clearSelectedContractor } =
      this.experimentAttributesStore;

    const contractor = getContractor(organizationId);

    if (!contractor) {
      clearSelectedContractor();

      return;
    }

    setSelectedContractor(contractor);
  };

  fetchContractorRegion = async (code: string): Promise<IDictionaryEntity[]> => {
    const { setCurrentContractorRegion } = this.experimentAttributesStore;
    const { getCurrentContractorRegion } = this.experimentAttributesService;

    const response = await getCurrentContractorRegion(code);

    if (response?.content) {
      const [currentContractorRegion] = response.content;

      setCurrentContractorRegion(currentContractorRegion);

      return response.content;
    }
  };

  clearStoreAfterLeavePage = (): void => {
    const { clearStore } = this.experimentAttributesStore;

    clearStore();
  };

  changeDropdownPageNumber = (dropdownName: string): void => {
    const { setDropdownPageNumbers, dropdownPageNumbers } = this.experimentAttributesStore;

    setDropdownPageNumbers(dropdownName, dropdownPageNumbers[dropdownName] + 1);
  };
}

export default ExperimentAttributesController;
