import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { ModalFooter, useModal } from '@farmlink/farmik-ui';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { isValid } from 'date-fns';

import {
  useCheckChangedForm,
  useForm,
} from '../../../../../../../../../../../common/features/form/utils/hooks';
import { useStore } from '../../../../../../../../../../../common/utils/helpers/mobx';
import { TechOperationController } from '../../mobx/controllers/TechOperation.controller';
import { ITechOperationForm, techOperationFormConfig } from '../../config/forms/techOperationForm';
import { CalculationController } from '../../mobx/controllers/Calculation/Calculation.controller';
import { CalculationStore } from '../../mobx/store/Calculation/Calculation.store';
import { TechOperationStore } from '../../mobx/store/Calculation/TechOperation.store';
import {
  REQUIRED_TEXT,
  START_DATE_NOT_AFTER_END_DATE_ERROR_TEXT,
} from '../../../../../../../../../../../common/utils/constants/dateValidation';
import { ISeason } from '../../../../../../../../../../../../api/models/as-fields/seasons';
import { CreateExperimentStore } from '../../../../../../mobx/stores';
import { useIsOverflow } from '../../../../../../../../../../../common/utils/hooks';
import { IExperimentCultureZone } from '../../../../../../../../../../../../api/models/as-fields/experiments';
import { IInventoryValuesFormModalPayload } from '../InventoryValuesForm/InventoryValuesForm';
import { ExecutionStore } from '../../../../../Execution/mobx/stores';
import { ExecutionController } from '../../../../../Execution/mobx/controllers';
import { createExecutionTableRowId as createRowId } from '../../../../../Execution/helpers';

import Styled from './OperationForm.style';

interface IOperationForm {
  formType: 'edit' | 'add';
}

export interface IOperationFormModalPayload {
  experimentId: string;
  selectedCultureZone: IExperimentCultureZone;
  onCreate: (form: ITechOperationForm) => string;
}

const OperationForm: FC<IOperationForm> = ({ formType }) => {
  const { selectedExp } = useStore(CreateExperimentStore);

  const executionStore = useStore(ExecutionStore);
  const executionController = useStore(ExecutionController);

  const { experimentId } = useParams<{ experimentId: string }>();
  const form = useForm<ITechOperationForm>(techOperationFormConfig.formKey);
  const {
    elements,
    registerForm,
    submitForm,
    addOptionList,
    addDatePickerParams,
    setElementErrorShow,
    formData,
    changeListOfFormValue,
    blockElement,
    changeElementData,
  } = form;

  const isRequiredFieldsNotEmpty = useMemo(
    () =>
      _.values(
        _.omit(formData, [
          'phenophaseFrom',
          'phenophaseTo',
          'bbshFrom',
          'bbshTo',
          'usageMethodType',
        ])
      ).every(item => Boolean(item)),
    [formData]
  );

  const { activeCultureZone, currentTechOperation, getExperimentStepById } =
    useStore(CalculationStore);
  const { onChangeTechOperation } = useStore(CalculationController);
  const {
    getBbchCodeById,
    getPhenophaseCodeById,
    getBbchOptionList,
    getPhenophaseOptionList,
    getPhenophases,
    getTechOperationTypesOptionList,
    createTechOperation,
    updateTechOperation,
    getCurrentSeason,
    getUsageMethodTypeOptions,
  } = useStore(TechOperationController);
  const { phenphase: currentPhenphases, bbch: currentBbches } = useStore(TechOperationStore);

  const { isFormChanged } = useCheckChangedForm();

  const { closeModal, openModalByModalId, getModalPayload } = useModal();
  const modalPayload = (getModalPayload() || {}) as IOperationFormModalPayload;

  const ref = useRef();
  const isOverflow = useIsOverflow(ref);

  const [currentSeason, setCurrentSeason] = useState<ISeason | null>(null);

  const currentExperimentId = useMemo(
    () => selectedExp?.id ?? experimentId,
    [selectedExp, experimentId]
  );

  const {
    startDate: StartDate,
    endDate: EndDate,
    price: Price,
    typeOperation: TypeOperation,
    usageMethodType: UsageMethodType,
    bbshFrom: BbshFrom,
    bbshTo: BbshTo,
    name: Name,
    phenophaseFrom: PhenophaseFrom,
    phenophaseTo: PhenophaseTo,
  } = elements;

  useEffect(() => {
    registerForm(techOperationFormConfig);

    (async () => {
      /**
       * Когда режим редактирования, не выполняем запрос за списком операций, так как
       * запрещена смена её названия.
       */

      if (formType !== 'edit') {
        const techOperationsTypes = await getTechOperationTypesOptionList();
        addOptionList('typeOperation', techOperationsTypes);
      }

      /**
       * Отображаем при загрузке модального окна текущую фенофазу, чтобы данные не
       * прыгали.
       */
      if (currentTechOperation?.phenophase) {
        addOptionList('phenophaseFrom', [
          {
            label: currentTechOperation?.phenophase.parent.name,
            value: currentTechOperation?.phenophase.parent.id,
          },
        ]);
      }

      if (currentTechOperation?.phenophaseEnd) {
        addOptionList('phenophaseTo', [
          {
            label: currentTechOperation?.phenophaseEnd.parent.name,
            value: currentTechOperation?.phenophaseEnd.parent.id,
          },
        ]);
      }

      await getPhenophases(modalPayload?.selectedCultureZone ?? activeCultureZone);
      const phenophase = getPhenophaseOptionList();
      addOptionList('phenophaseFrom', phenophase);
      addOptionList('phenophaseTo', phenophase);

      const usageMethodTypeList = await getUsageMethodTypeOptions();
      addOptionList('usageMethodType', usageMethodTypeList);
    })();

    (async () => {
      const organizationId = selectedExp?.organization?.id;
      const seasonYear = selectedExp?.seasonYear;

      const seasonInfo = await getCurrentSeason(organizationId, seasonYear);

      setCurrentSeason(seasonInfo);
    })();
  }, []);

  useEffect(() => {
    if (currentSeason) {
      const minDate = new Date(currentSeason?.startDate);
      const maxDate = new Date(currentSeason?.endDate);

      const dateRangeParams = {
        minDate,
        maxDate,
      };

      if (formType === 'add') {
        addDatePickerParams('startDate', {
          startDate: minDate,
          dateRange: dateRangeParams,
        });

        addDatePickerParams('endDate', {
          startDate: maxDate,
          dateRange: dateRangeParams,
        });
      } else {
        addDatePickerParams('startDate', {
          dateRange: dateRangeParams,
        });

        addDatePickerParams('endDate', {
          dateRange: dateRangeParams,
        });
      }
    }
  }, [formType, currentSeason]);

  useEffect(() => {
    if (formData?.endDate && formData?.startDate) {
      const startDate = new Date(formData.startDate);
      const endDate = new Date(formData.endDate);

      if (startDate.getTime() > endDate.getTime()) {
        setElementErrorShow('endDate', true, START_DATE_NOT_AFTER_END_DATE_ERROR_TEXT);
      } else {
        setElementErrorShow('endDate', false, REQUIRED_TEXT);
      }
    }
  }, [formData?.endDate, formData?.startDate]);

  useEffect(() => {
    if (formData?.phenophaseFrom) {
      const bbch = getBbchOptionList(formData.phenophaseFrom);

      if (bbch && bbch.length > 0) {
        addOptionList('bbshFrom', bbch);
        changeListOfFormValue({ bbshFrom: bbch[0].value });
        checkBBCHError({ bbchTo: formData.bbshTo, bbchFrom: bbch[0].value });
      } else {
        changeListOfFormValue({ phenophaseFrom: '' });
      }
    } else {
      changeListOfFormValue({ bbshFrom: '' });
    }
  }, [formData?.phenophaseFrom]);

  useEffect(() => {
    if (formData?.phenophaseTo) {
      const bbch = getBbchOptionList(formData.phenophaseTo);

      if (bbch && bbch.length > 0) {
        addOptionList('bbshTo', bbch);
        changeListOfFormValue({ bbshTo: bbch[0].value });
        checkBBCHError({ bbchTo: bbch[0].value, bbchFrom: formData.bbshFrom });
      } else {
        changeListOfFormValue({ phenophaseTo: '' });
      }
    } else {
      changeListOfFormValue({ bbshTo: '' });
    }
  }, [formData?.phenophaseTo]);

  useEffect(() => {
    if (!formData?.bbshFrom) {
      setTimeout(() => {
        changeListOfFormValue({ phenophaseFrom: '' });
      });
    }
  }, [formData?.bbshFrom]);

  useEffect(() => {
    if (!formData?.bbshTo) {
      setTimeout(() => {
        changeListOfFormValue({ phenophaseTo: '' });
      });
    }
  }, [formData?.bbshTo]);

  useEffect(() => {
    checkBBCHError({ bbchTo: formData?.bbshTo, bbchFrom: formData?.bbshFrom });
  }, [formData?.bbshFrom, formData?.bbshTo]);

  useEffect(() => {
    if (!formData?.phenophaseFrom && !formData?.phenophaseTo) {
      changeElementData('phenophaseTo', {
        schema: {
          isShownError: false,
          errorTitle: '',
        },
      });
      changeElementData('phenophaseFrom', {
        schema: {
          isShownError: false,
          errorTitle: '',
        },
      });
    } else {
      const fromCode = getPhenophaseCodeById(formData?.phenophaseFrom);
      const toCode = getPhenophaseCodeById(formData?.phenophaseTo);

      if (fromCode > toCode) {
        changeElementData('phenophaseTo', {
          schema: {
            isShownError: true,
            errorTitle: '"Фенофаза до" не должна быть меньше "Фенофазы от"',
          },
        });
      } else {
        changeElementData('phenophaseTo', {
          schema: {
            isShownError: false,
            errorTitle: '',
          },
        });
      }
    }
  }, [formData?.phenophaseFrom, formData?.phenophaseTo]);

  const checkBBCHError = (bbchInfo: { bbchTo: string; bbchFrom: string }): void => {
    if (!bbchInfo.bbchFrom && !bbchInfo.bbchTo) {
      changeElementData('bbshTo', {
        schema: {
          isShownError: false,
          errorTitle: '',
        },
      });
    } else {
      const fromCode = getBbchCodeById(bbchInfo.bbchFrom);
      const toCode = getBbchCodeById(bbchInfo.bbchTo);

      if (fromCode > toCode) {
        changeElementData('bbshTo', {
          schema: {
            isShownError: true,
            errorTitle: '"BBCH до" не должна быть меньше "BBCH от"',
          },
        });
      } else {
        changeElementData('bbshTo', {
          schema: {
            isShownError: false,
            errorTitle: '',
          },
        });
      }
    }
  };

  const addOnSuccess = () => {
    submitForm(async forms => {
      const isOnlyOneOfThePhenophasesExist =
        (forms?.phenophaseTo && forms?.phenophaseFrom.length === 0) ||
        (forms?.phenophaseTo.length === 0 && forms?.phenophaseFrom);

      const emptyField = forms?.phenophaseFrom.length === 0 ? 'phenophaseFrom' : 'phenophaseTo';
      if (isOnlyOneOfThePhenophasesExist) {
        setElementErrorShow(emptyField, true, '"Фенофаза до" не должна быть меньше "Фенофазы от"');
      } else {
        if (modalPayload?.onCreate) {
          const createdStepId = modalPayload.onCreate(forms);
          closeModal();

          /*
        Временно отключили возможность добавления ТМЦ сразу после того,
        как мы создали операцию, так как есть сложности в обработки на бэкенде.
         */

          return;

          const payload: IInventoryValuesFormModalPayload = {
            experimentId: modalPayload.experimentId,
            currentFertilizerIdList: executionStore.getFertilizerIdList(createdStepId),
            onCreate: (inventoryValuesForm, selectedItem) => {
              const createdItemByFertilizerId = executionController.createNutritionHistoryItem(
                inventoryValuesForm,
                createdStepId,
                createRowId(createdStepId, 'fertilizers'),
                selectedItem,
                ''
              );

              return createdItemByFertilizerId;
            },
          };

          openModalByModalId('addPlanInventoryValues', payload);

          return;
        }

        const id = await createTechOperation(forms, activeCultureZone, currentExperimentId);

        if (id) {
          const experimentStep = getExperimentStepById(id);
          onChangeTechOperation(experimentStep);

          const withoutUsageMethodId = '8b08fd43-1033-4583-b306-d63a8864ed57';

          if (forms.usageMethodType !== withoutUsageMethodId) {
            openModalByModalId('addPlanInventoryValues');
          } else {
            closeModal();
          }
        } else {
          closeModal();
        }
      }
    });
  };

  const editOnSuccess = useCallback(() => {
    submitForm(async forms => {
      const isOnlyOneOfThePhenophasesExist =
        (forms?.phenophaseTo && forms?.phenophaseFrom.length === 0) ||
        (forms?.phenophaseTo.length === 0 && forms?.phenophaseFrom);

      const emptyField = forms?.phenophaseFrom.length === 0 ? 'phenophaseFrom' : 'phenophaseTo';

      if (isOnlyOneOfThePhenophasesExist) {
        setElementErrorShow(emptyField, true, '"Фенофаза до" не должна быть меньше "Фенофазы от"');
      } else {
        await updateTechOperation(
          forms,
          activeCultureZone,
          currentTechOperation,
          currentExperimentId
        );

        closeModal();
      }
    });
  }, [activeCultureZone, currentExperimentId, currentTechOperation]);

  const getPhenphaseByBbchId = useCallback(
    (bbchId?: string) => {
      if (!bbchId) {
        return '';
      }

      const currentBbch = currentBbches.find(item => item.id === bbchId);
      const currentPhenophase = currentPhenphases.find(item => item.id === currentBbch?.parentId);

      return currentPhenophase?.id ?? '';
    },
    [currentBbches, currentPhenphases]
  );

  useEffect(() => {
    if (formType === 'edit') {
      // Если у нас режим редактирования, то блокируем поле 'Тип операции'.
      blockElement('typeOperation', true);

      /**
       * Когда режим редактирования, то опшен для поля "Тип операции" явно
       * хардкодим из полученной модели. Это необходимо для того, чтобы правильно
       * отобразить наименование без лишних запросов на бэк.
       */
      if (currentTechOperation?.techOperationType) {
        addOptionList('typeOperation', [
          {
            label: currentTechOperation?.techOperationType?.name,
            value: currentTechOperation?.techOperationType?.id,
          },
        ]);
      }

      if (currentTechOperation) {
        const phenophaseFrom = getPhenphaseByBbchId(currentTechOperation?.phenophase?.id);
        const phenophaseTo = getPhenphaseByBbchId(currentTechOperation?.phenophaseEnd?.id);

        changeListOfFormValue({
          name: currentTechOperation.name,
          startDate: isValid(new Date(currentTechOperation.operation?.startDate))
            ? new Date(currentTechOperation.operation.startDate)
            : new Date(),
          endDate: isValid(new Date(currentTechOperation.operation?.endDate))
            ? new Date(currentTechOperation.operation.endDate)
            : new Date(),
          typeOperation: currentTechOperation.techOperationType.id,
          phenophaseFrom,
          phenophaseTo,
          bbshFrom: currentTechOperation?.phenophase?.id ?? '',
          bbshTo: currentTechOperation?.phenophaseEnd?.id ?? '',
          usageMethodType: currentTechOperation?.usageMethodType?.id,
          price: String(currentTechOperation?.operation?.servicePricePerArea ?? ''),
        });
      }
    }
  }, [formType, getPhenphaseByBbchId]);

  const successButton = useMemo(() => {
    const handler = formType === 'add' ? addOnSuccess : editOnSuccess;

    return {
      title: formType === 'add' ? 'Добавить' : 'Сохранить',
      handler,
      disabled: formType === 'add' ? !isRequiredFieldsNotEmpty : !isFormChanged,
    };
  }, [formType, isFormChanged, addOnSuccess, editOnSuccess, isRequiredFieldsNotEmpty]);

  const denyButton = useMemo(() => {
    return { title: 'Отменить', handler: closeModal };
  }, [closeModal]);

  return (
    <Styled.Wrapper>
      <Styled.ContentWrapper ref={ref} $isOverflow={isOverflow}>
        <Styled.Attribute width="100%" marginBottom="24px">
          {Name && <Name />}
        </Styled.Attribute>
        <Styled.Attribute width="100%" marginBottom="24px">
          {TypeOperation && <TypeOperation />}
        </Styled.Attribute>
        <Styled.Attribute width="100%" marginBottom="24px">
          {UsageMethodType && <UsageMethodType />}
        </Styled.Attribute>
        <Styled.Attribute width="100%" marginBottom="24px">
          {Price && <Price />}
        </Styled.Attribute>
        <Styled.Attribute width="45%" marginBottom="24px" marginRight="12px">
          {StartDate && <StartDate />}
        </Styled.Attribute>
        <Styled.Attribute width="45%" marginBottom="24px">
          {EndDate && <EndDate />}
        </Styled.Attribute>
        <Styled.Attribute width="45%" marginBottom="24px" marginRight="12px">
          {PhenophaseFrom && <PhenophaseFrom />}
        </Styled.Attribute>
        <Styled.Attribute width="45%" marginBottom="24px">
          {BbshFrom && <BbshFrom />}
        </Styled.Attribute>
        <Styled.Attribute width="45%" marginBottom="24px" marginRight="12px">
          {PhenophaseTo && <PhenophaseTo />}
        </Styled.Attribute>
        <Styled.Attribute width="45%" marginBottom="24px">
          {BbshTo && <BbshTo />}
        </Styled.Attribute>
      </Styled.ContentWrapper>

      <Styled.FooterWrapper $isOverflow={isOverflow}>
        <ModalFooter successButton={successButton} denyButton={denyButton} />
      </Styled.FooterWrapper>
    </Styled.Wrapper>
  );
};

export default observer(OperationForm);
