import { FC, useCallback } from 'react';
import { observer } from 'mobx-react';

import { ICultureZoneCell } from '../../utils/hooks/zonesListTableHooks/zonesListTable.hooks';
import { RadioButton } from '../RadioButton';
import { EExperimentCultureZoneType } from '../../../../../../../../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { useStore } from '../../../../../../../../../../../../../common/utils/helpers/mobx';
import { ZonesController } from '../../../../mobx/controllers';
import { useMap } from '../../../../../../../../../../../../../common/features/experimentsMap/hooks';
import { ZonesStore } from '../../../../mobx/stores';
import { CreateExperimentStore } from '../../../../../../../../mobx/stores';
import { EExperimentStatus } from '../../../../../../../../../../../../../../api/models/as-fields/experiments';

interface IProps {
  rowData?: ICultureZoneCell;
}

export const ZoneListRadioButtonCell: FC<IProps> = observer(({ rowData }) => {
  const { setTableHasChanged } = useStore(ZonesStore);
  const { changeZoneType } = useStore(ZonesController);
  const { selectedExp } = useStore(CreateExperimentStore);

  const { changeCultureZoneStyle } = useMap();

  const handleChange = useCallback(
    value => {
      setTableHasChanged(true);

      changeZoneType(
        { ...rowData.fwExpCultureZone, type: value, styleType: value },
        changeCultureZoneStyle
      );
    },
    [rowData.fwExpCultureZone]
  );

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}
      onClick={e => e.stopPropagation()}
    >
      <RadioButton
        name="type"
        checked={rowData.type === EExperimentCultureZoneType.Control}
        icon={rowData.type === EExperimentCultureZoneType.Control ? 'controlField' : 'lock'}
        color={'blue'}
        iconName="Контрольный"
        onChange={() => handleChange(EExperimentCultureZoneType.Control)}
        disabled={
          rowData?.isDisabledControlTypeSelection ||
          !rowData?.fwExpCultureZone?.isSelected ||
          selectedExp?.status !== EExperimentStatus.Draft
        }
        isIgnoreDisabledStyles={rowData?.fwExpCultureZone?.isSelected}
      >
        Контроль
      </RadioButton>
      <RadioButton
        name="type"
        icon={
          rowData.type === EExperimentCultureZoneType.Experiment &&
          rowData?.fwExpCultureZone?.isSelected
            ? 'experimentField'
            : 'lock'
        }
        iconName="Опытный"
        checked={rowData.type === EExperimentCultureZoneType.Experiment}
        onChange={() => handleChange(EExperimentCultureZoneType.Experiment)}
        disabled={
          !rowData?.fwExpCultureZone?.isSelected || selectedExp?.status !== EExperimentStatus.Draft
        }
        isIgnoreDisabledStyles={rowData?.fwExpCultureZone?.isSelected}
      >
        Опыт
      </RadioButton>
    </div>
  );
});
