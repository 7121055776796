import { FC, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { AutoTooltip, Icon, useShowTooltip } from '@farmlink/farmik-ui';
import { useLocation } from 'react-router-dom';

import {
  EExperimentCultureZoneType,
  IExperimentCultureZone,
} from '../../../../../../../api/models/as-fields/experiments/ExperimentCultureZone';
import { useStore } from '../../../../../utils/helpers/mobx';
import { CalculationStore } from '../../../../../../dashboard/modules/experiments/modules/createExperiment/modules/calculation/containers/Calculation/mobx/store/Calculation/Calculation.store';
import { HistoryStore } from '../../../../../../dashboard/modules/experiments/modules/createExperiment/modules/history/containers/History/mobx/stores/History.store';
import { ExecutionStore } from '../../../../../../dashboard/modules/experiments/modules/createExperiment/modules/Execution/mobx/stores';
import { TExecutionZone } from '../../../../../../dashboard/modules/experiments/modules/createExperiment/modules/Execution/types';

import Styled from './PageAndFieldInfo.style';

const PageAndFieldInfo: FC = () => {
  const { ref, isShowTooltip } = useShowTooltip();

  const location = useLocation();

  const [descriptionInfo, setDescriptionInfo] = useState<{
    currentPath: string;
    currentCultureZoneInfo: IExperimentCultureZone | TExecutionZone | null;
    currentCultureZoneName: string;
    fieldInfoWidth: string;
  }>();

  const calculationStore = useStore(CalculationStore);
  const historyStore = useStore(HistoryStore);
  const executionStore = useStore(ExecutionStore);

  useEffect(() => {
    if (location.pathname.includes('history')) {
      setDescriptionInfo({
        currentPath: 'История внесения',
        currentCultureZoneInfo: historyStore.activeCultureZone,
        currentCultureZoneName: historyStore.activeCultureZone?.cultureZone?.name,
        fieldInfoWidth: '356px',
      });
    } else if (location.pathname.includes('calculation')) {
      setDescriptionInfo({
        currentPath: 'Расчёт системы питания',
        currentCultureZoneInfo: calculationStore.activeCultureZone,
        currentCultureZoneName: calculationStore.activeCultureZone?.cultureZone?.name,
        fieldInfoWidth: '330px',
      });
    } else if (location.pathname.includes('execution')) {
      setDescriptionInfo({
        currentPath: 'Исполнение',
        currentCultureZoneInfo: executionStore.currentZoneInfo,
        currentCultureZoneName: executionStore.currentZoneInfo?.name,
        fieldInfoWidth: '356px',
      });
    }
  }, [location.pathname]);

  const iconType = useMemo(() => {
    return descriptionInfo?.currentCultureZoneInfo?.type === EExperimentCultureZoneType.Control
      ? 'controlField'
      : 'experimentField';
  }, [descriptionInfo?.currentCultureZoneInfo?.type]);

  return (
    <Styled.Wrapper>
      <Styled.PageName>{descriptionInfo?.currentPath}</Styled.PageName>
      <Styled.FieldInfoWrapper $fieldInfoWidth={descriptionInfo?.fieldInfoWidth}>
        <Styled.IconWrapper>
          <Icon icon={iconType} size={'medium'} />
        </Styled.IconWrapper>

        <AutoTooltip
          content={descriptionInfo?.currentCultureZoneName ?? ''}
          disabled={!isShowTooltip}
        >
          <Styled.NameWrapper ref={ref}>
            {descriptionInfo?.currentCultureZoneName ?? ''}
          </Styled.NameWrapper>
        </AutoTooltip>
      </Styled.FieldInfoWrapper>
    </Styled.Wrapper>
  );
};

PageAndFieldInfo.displayName = 'PageAndFieldInfo';

export default observer(PageAndFieldInfo);
