import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useContextualHelpActions } from '@farmlink/farmik-ui';
import { IContextualHelpTooltipPosition } from '@farmlink/farmik-ui/dist/feature/ContextualHelp/components/ContextualHelpToolTipIcon/ContextualHelpToolTipIcon';


import { useStore } from '../../../../../../../common/utils/helpers/mobx';
import { TAuditsParams } from '../../../../models/params/AuditsParams.model';
import { IDynamicTableConfig } from '../../../../../../../common/features/DynamicTable/models';
import { DynamicTable } from '../../../../../../../common/features/DynamicTable';
import { AuditStepFooterContainer } from '../../containers';
import { NavigationTabsController } from '../../../../../../../common/features/NavigationTabs/mobx';
import { usePageContextualHelp } from '../../../../../../../common/hooks/usePageContextualHelp';
import {
  ContextualPaths,
  EContextualParentPath,
} from '../../../../../../../common/constants/contextualPath';
import { useWarningBeforeLeavingThePage } from '../../../../../../../common/utils/hooks';
import { DynamicTableStore } from '../../../../../../../common/features/DynamicTable/mobx/stores';
import { AuditStore } from '../../mobx/store';

import { AuditPhotosController } from './mobx/controller';
import { AuditPhotosNoDataPlug } from './components';
import Styled from './AuditPhotos.styles';

const AuditPhotos: FC = () => {
  const { createTableConfig, saveImagesConfig } = useStore(AuditPhotosController);

  const { selectedAudit } = useStore(AuditStore);

  const { goToNextTab } = useStore(NavigationTabsController);

  const { isImagesTableIsEdited, clearStore } = useStore(DynamicTableStore);

  const [tableConfig, setTableConfig] = useState<IDynamicTableConfig | null>(null);

  const showContent = tableConfig?.headColumn?.stages.length > 0;

  const isEditingAllowed = tableConfig?.system?.isEnableEditing;

  const showSaveButton = showContent && isEditingAllowed;

  const { auditId } = useParams<TAuditsParams>();

  useWarningBeforeLeavingThePage(isImagesTableIsEdited);
  usePageContextualHelp(EContextualParentPath.AuditsAuditPhotos);
  const helpActions = useContextualHelpActions();

  const tooltipConfig: IContextualHelpTooltipPosition = {
    position: 'bottom-right',
    positionAdjustment: { x: -160, y: 0 },
  };

  const ContextualHelpIcon = helpActions.getContextualHelpIcon(
    EContextualParentPath.AuditsAuditPhotos,
    ContextualPaths.AuditsAuditPhotosPhotosTableTitle,
    tooltipConfig
  );

  useEffect(() => {
    if (selectedAudit && auditId) {
      const isEnableEditing = selectedAudit?.canUpdateStatus;

      (async () => {
        const createdConfig = await createTableConfig(auditId, isEnableEditing);

        if (createdConfig) {
          setTableConfig(createdConfig);
        }
      })();
    }
  }, [auditId, selectedAudit]);

  useEffect(() => {
    return () => {
      clearStore();
    };
  }, []);

  const handleContinueClick = useCallback(() => {
    saveImagesConfig(auditId).then(() => {
      goToNextTab();
    });
  }, [auditId]);

  return (
    <>
      <Styled.Wrapper
        title="Фотографии"
        ContextualHelpIcon={ContextualHelpIcon}
        $flex={!showContent}
      >
        {showContent ? <DynamicTable config={tableConfig} /> : <AuditPhotosNoDataPlug />}
      </Styled.Wrapper>
      {showSaveButton ? (
        <AuditStepFooterContainer
          stepName="audit-photos"
          successTitle="Сохранить"
          onContinueClick={handleContinueClick}
        />
      ) : (
        <Styled.FooterNoDataPlug />
      )}
    </>
  );
};

AuditPhotos.displayName = 'AuditPhotos';

export default observer(AuditPhotos);
